import * as ROUTES from "./ROUTES";
import {AreaContentDefinition} from "../models/AreaContentDefinition.model";
import { DataService } from './data.service';
import {GenericResponse} from "../models/response/GenericResponse.model";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Template } from '../models/Template.model';
import {TemplateArea} from "../models/TemplateArea.model";
import {TemplateColor} from "../models/TemplateColor.model";

@Injectable({
    providedIn: 'root',
})
export class TemplateService{

    public static AREA_ORIENTATION_TYPES:{key: string, name:string}[] = [{key: 'horizontal', name: 'Horizontal'}, {key: 'vertical', name: 'Vertical'}];
    public static AREA_TYPES:{key: string, name:string}[] = [{key: 'image', name: 'Image'}, {key: 'text', name: 'Text'}];

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) {}

    public create(template: Template): Observable<GenericResponse<Template>> {
        return this.http.post<GenericResponse<Template>>(`${ROUTES.TEMPLATE_ROUTE}/`, template, this.dataService.getHttpOptionsWithToken());
    }

    public update(template: Template): Observable<GenericResponse<Template>> {
        return this.http.put<GenericResponse<Template>>(`${ROUTES.TEMPLATE_ROUTE}/${template.templateId}/`,
            template,this.dataService.getHttpOptionsWithToken());
    }

    public delete(templateId: string): Observable<GenericResponse<string>> {
        return this.http.delete<GenericResponse<string>>(`${ROUTES.TEMPLATE_ROUTE}/${templateId}/`, this.dataService.getHttpOptionsWithToken());
    }

    public get(templateId: string): Observable<GenericResponse<Template>> {
        return this.http.get<GenericResponse<Template>>(`${ROUTES.TEMPLATE_ROUTE}/${templateId}/`, this.dataService.getHttpOptionsWithToken());
    }

    public getAll(onlyActive: boolean = true): Observable<GenericResponse<Template[]>> {
        return this.http.get<GenericResponse<Template[]>>(`${ROUTES.TEMPLATE_ROUTE}/all/${!onlyActive}`, this.dataService.getHttpOptionsWithToken());
    }

    // Template Areas:

    public getAllTemplateAreas(templateId: string): Observable<GenericResponse<TemplateArea[]>>{
        return this.http.get<GenericResponse<TemplateArea[]>>(`${ROUTES.TEMPLATE_ROUTE}/${templateId}/areas/`, this.dataService.getHttpOptionsWithToken());
    }

    public createTemplateArea(templateArea: TemplateArea): Observable<GenericResponse<TemplateArea>>{
        return this.http.post<GenericResponse<TemplateArea>>(`${ROUTES.TEMPLATE_ROUTE}/${templateArea.templateId}/areas/`,
            templateArea, this.dataService.getHttpOptionsWithToken());
    }

    public updateTemplateArea(templateArea: TemplateArea): Observable<GenericResponse<TemplateArea>>{
        return this.http.put<GenericResponse<TemplateArea>>(`${ROUTES.TEMPLATE_ROUTE}/${templateArea.templateId}/areas/${templateArea.areaId}/`,
            templateArea, this.dataService.getHttpOptionsWithToken());
    }

    public deleteTemplateArea(templateArea: TemplateArea): Observable<GenericResponse<string>>{
        return this.http.delete<GenericResponse<string>>(`${ROUTES.TEMPLATE_ROUTE}/${templateArea.templateId}/areas/${templateArea.areaId}/`,
            this.dataService.getHttpOptionsWithToken());
    }

    public getAllAreaContentDefinition(): Observable<GenericResponse<AreaContentDefinition[]>>{
        return this.http.get<GenericResponse<AreaContentDefinition[]>>(
            `${ROUTES.TEMPLATE_ROUTE}/areaContentDefinition/`, this.dataService.getHttpOptionsWithToken());
    }

    // Template Colors:

    public getAllTemplateColors(templateId: string): Observable<GenericResponse<TemplateColor[]>>{
        return this.http.get<GenericResponse<TemplateColor[]>>(`${ROUTES.TEMPLATE_ROUTE}/${templateId}/colors/`, this.dataService.getHttpOptionsWithToken());
    }

    public createTemplateColor(templateColor: TemplateColor): Observable<GenericResponse<TemplateColor>>{
        return this.http.post<GenericResponse<TemplateColor>>(`${ROUTES.TEMPLATE_ROUTE}/${templateColor.templateId}/colors/`,
            templateColor, this.dataService.getHttpOptionsWithToken());
    }

    public updateTemplateColor(templateColor: TemplateColor): Observable<GenericResponse<TemplateColor>>{
        return this.http.put<GenericResponse<TemplateColor>>(`${ROUTES.TEMPLATE_ROUTE}/${templateColor.templateId}/colors/${templateColor.templateColorId}/`,
            templateColor, this.dataService.getHttpOptionsWithToken());
    }

    public deleteTemplateColor(templateColor: TemplateColor): Observable<GenericResponse<string>>{
        return this.http.delete<GenericResponse<string>>(`${ROUTES.TEMPLATE_ROUTE}/${templateColor.templateId}/colors/${templateColor.templateColorId}/`,
            this.dataService.getHttpOptionsWithToken());
    }

    public upload(file): Observable<{ id: string }>{
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<{ id: string }>(`${ROUTES.TEMPLATE_ROUTE}/upload/`, formData, this.dataService.getHttpOptionsWithToken());
    }

    public getFile(fileId: string): Observable<any>{
        return this.http.get<any>(`${ROUTES.TEMPLATE_ROUTE}/file/${fileId}`, this.dataService.getHttpOptionsWithTokenBlodResponse());
    }

    public getFileName(fileId: string): Observable<GenericResponse<string>>{
        return this.http.get<GenericResponse<string>>(`${ROUTES.TEMPLATE_ROUTE}/filename/${fileId}`, this.dataService.getHttpOptionsWithToken());
    }
}

<nb-layout>
  <nb-layout-column class="p-0">
    <div class="bg">
      <div class="container">

        <div class="row">
          <div class="col-12">
            <h3>PortToolio</h3>
          </div>
        </div>

        <div class="row inputs">
          <div class="col-12">
            <input nbInput placeholder="Nutzername..." class="username" [(ngModel)]="loginName" (keyup)="onKeypress($event)">
          </div>
          <div class="col-12">
            <input nbInput placeholder="Passwort..." type="password" [(ngModel)]="password" (keyup)="onKeypress($event)">
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <span class="reset-password-link" (click)="navigateToResetPassword()">Passwort vergessen?</span>
          </div>
        </div>

        <div class="row btnRow">
          <div class="col-12">
            <button nbButton (click)="login()">Anmelden</button>
          </div>
        </div>

      </div>
    </div>
  </nb-layout-column>
</nb-layout>

import { CreateUserRequest } from "./CreateUserRequest.model";
import { User } from "../../User.model";

export class UpdateUserRequest extends CreateUserRequest {

    public userId: string;

    constructor(user: User) {
        super(user);
        this.userId = user.userId;
    }

    public toUser(user: User): User {
        const updateUser = super.toUser(user);
        updateUser.userId = this.userId;
        return updateUser;
    }

}

<nb-layout>
  <nb-layout-column class="p-0">
    <div class="bg">
      <div class="container">

        <div class="row">
          <div class="col-12">
            <h5>PortToolio - Passwort zur&uuml;cksetzen</h5>
          </div>
        </div>

        <div class="row text">
          <div class="col-12">
            <span>Geben Sie Ihr gew&uuml;nschtes Passwort ein und best&auml;tigen Sie dieses.</span>
          </div>
        </div>

        <div class="row inputs">
          <div class="col-12">
            <input nbInput fullWidth type="password" [(ngModel)]="password" placeholder="Passwort">
          </div>
          <div class="col-12">
            <input nbInput fullWidth type="password" [(ngModel)]="confirmPassword" placeholder="Passwort bestätigen">
          </div>
        </div>

        <div class="row btnRow">
          <div class="col-12">
            <button nbButton (click)="resetPassword()">Passwort Zur&uuml;cksetzen anfragen</button>
          </div>
        </div>

      </div>
    </div>
  </nb-layout-column>
</nb-layout>
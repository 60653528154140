import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastService } from 'app/services/toast.service';
import { UserService } from 'app/services/user.service';

@Component({
    selector: 'ngx-request-password-reset',
    styleUrls: ['./request-password-reset.component.scss'],
    templateUrl: './request-password-reset.component.html',
})
export class RequestPasswordResetComponent {

    public email: string;

    constructor(
        private userService: UserService,
        private toastService: ToastService,
        private router: Router
    ) { }

    public async resetPassword(): Promise<void> {
        if (this.email != undefined && this.email != '') {
            await this.userService.resetPassword(this.email).toPromise()
            .catch((err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.REQUEST_RESET_PASSWORD_FAILED, 3000);
            })
            // TODO: Responsetype angeben
            .then(res => {
                if (res == undefined) return;
                this.router.navigate(['login'], {state: {resetPWSuccess: true}});
            });
        } else {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.REQUEST_RESET_PASSWORD_FAILED, 3000);
        }
    }
}

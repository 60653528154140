import * as ROUTES from "./ROUTES";
import { DataService } from "./data.service";
import {GenericResponse} from "../models/response/GenericResponse.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Project } from "app/models/Project.model";
import {ProjectItem} from '../models/ProjectItem.model';
import {ProjectState} from "../models/ProjectState.model";

@Injectable({
    providedIn: 'root',
})
export class ProjectService {

    constructor(
        private http: HttpClient,
        private dataService: DataService
    ) {}

    public getAllProjects(): Observable<GenericResponse<Project[]>> {
        return this.http.get<GenericResponse<Project[]>>(`${ROUTES.PROJECT_ROUTE}/all/`, this.dataService.getHttpOptionsWithToken());
    }

    public getProjectById(projectId: string): Observable<GenericResponse<Project>> {
        return this.http.get<GenericResponse<Project>>(`${ROUTES.PROJECT_ROUTE}/${projectId}/`, this.dataService.getHttpOptionsWithToken());
    }

    public deleteProject(projectId: string): Observable<GenericResponse<string>> {
        return this.http.delete<GenericResponse<string>>(`${ROUTES.PROJECT_ROUTE}/${projectId}/`, this.dataService.getHttpOptionsWithToken());
    }

    public createProject(project: Project): Observable<GenericResponse<Project>> {
        return this.http.post<GenericResponse<Project>>(`${ROUTES.PROJECT_ROUTE}/`, project, this.dataService.getHttpOptionsWithToken());
    }

    public updateProject(project: Project): Observable<GenericResponse<Project>> {
        return this.http.put<GenericResponse<Project>>(`${ROUTES.PROJECT_ROUTE}/${project.projectId}/`, project, this.dataService.getHttpOptionsWithToken());
    }

    public getProjectStates(): Observable<GenericResponse<ProjectState[]>>{
        return this.http.get<GenericResponse<ProjectState[]>>(`${ROUTES.PROJECT_ROUTE}/states/`, this.dataService.getHttpOptionsWithToken());
    }

    public getFile(fileId: string): Observable<any>{
        return this.http.get<any>(`${ROUTES.PROJECT_ROUTE}/file/${fileId}`, this.dataService.getHttpOptionsWithTokenBlodResponse());
    }

    public getPreprint(projectId: string): Observable<any>{
        return this.http.get<any>(`${ROUTES.PROJECT_ROUTE}/${projectId}/preprint/`, this.dataService.getHttpOptionsWithTokenBlobResponseHugeTimeout());
    }

    public triggerPrintCreation(projectId: string): Observable<GenericResponse<Project>>{
        return this.http.post<GenericResponse<Project>>(`${ROUTES.PROJECT_ROUTE}/${projectId}/print/`, {}, this.dataService.getHttpOptionsWithToken());
    }
    public getAllProjectItems(projectId: string): Observable<GenericResponse<ProjectItem[]>> {
        return this.http.get<GenericResponse<ProjectItem[]>>(`${ROUTES.PROJECT_ROUTE}/${projectId}/items/`, this.dataService.getHttpOptionsWithToken());
    }

}

<div class="">
    <div class="col-12 d-flex justify-content-center">
        <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
    </div>
</div>
<nb-card>
    <nb-card-header>
        <div class="row">
            <div class="col-sm-12">
                <h5 *ngIf="!edit">Kind hinzuf&uuml;gen</h5>
                <h5 *ngIf="edit">Kind bearbeiten</h5>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <form>
            <div class="row">
                <div class="col-12 mandatoryCol">
                    <span class="mandatory">*</span> Pflichtfeld
                </div>
            </div>
             <div class="form-group row">
                 <label class="col-sm-2 label" for="userAutocomplete">Benutzer <span class="mandatory">*</span></label>
                 <div class="col-sm-4">
                     <ngx-autocomplete
                             *ngIf="autocompleteItems !== undefined"
                             #userAutocomplete
                             id="userAutocomplete"
                             name="userAutocomplete"
                             [items]="autocompleteItems"
                             [(value)]="selectedAutocompleteItem"
                             (onSelect)="onUserSelect($event)"
                             (onChange)="onAutocompleteChange($event)"
                     ></ngx-autocomplete>
                 </div>
                 <label class="col-sm-2 label" for="name">Name <span class="mandatory">*</span></label>
                 <div class="col-sm-4">
                     <input #name id="name" name="name" placeholder="Name" [(ngModel)]="child.name" nbInput fullWidth>
                 </div>
             </div>
            <div class="form-group row">
                <label class="col-lg-2 col-xl-2 label" for="groupName">Gruppenname <span class="mandatory">*</span></label>
                <div class="col-lg-4 col-xl-4">
                    <input #groupName id="groupName" name="groupName" placeholder="Gruppenname" [(ngModel)]="child.groupName" nbInput fullWidth>
                </div>
                <!--
                <label class="col-sm-2 label" for="birthdate">Geburtsdatum <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input #birthdate id="birthdate" name="birthdate" placeholder="Geburtsdatum" [(ngModel)]="child.birthdate" nbInput fullWidth>
                </div>
                -->
            </div>
            <div class="form-group row">
                <label class="col-sm-2 label" for="startYear">Startjahr <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input #startYear id="startYear" name="startYear" placeholder="Startjahr" [(ngModel)]="child.startYear" nbInput fullWidth>
                </div>
                <label class="col-sm-2 label" for="endYear">Endjahr <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input #endYear id="endYear" name="endYear" placeholder="Endjahr" [(ngModel)]="child.endYear" nbInput fullWidth>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <div class="form-group row btnRow">
            <div class="col-sm-12">
                <button nbButton fullWidth (click)="submit()">Speichern</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>

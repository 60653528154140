import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class DataService{

    private token: string;

    // tslint:disable-next-line:no-empty
    constructor() { }

    public clearData(): void {
        localStorage.clear();
    }

    public getHttpOptionsWithToken(token?: string): object {
        const localToken = localStorage.getItem('token');
        if (token != undefined) {
            return {
                headers: new HttpHeaders(
                    {
                        'X-Auth-Token': token,
                        'Access-Control-Allow-Origin': '*',
                    }),
            };
        } else if (this.token != undefined) {
            return {
                headers: new HttpHeaders(
                    {
                        'X-Auth-Token': this.token,
                        'Access-Control-Allow-Origin': '*',
                    }),
            };
        } else if (localToken != undefined) {
            return {
                headers: new HttpHeaders(
                    {
                        'X-Auth-Token': localToken,
                        'Access-Control-Allow-Origin': '*',
                    }),
            };
        } else {
            return this.getHttpOptionsNoToken();
        }
    }

    public getHttpOptionsWithTokenBlodResponse(token?: string): object {
        const localToken = localStorage.getItem('token');
        if (token != undefined) {
            return {
                headers: new HttpHeaders(
                    {
                        'X-Auth-Token': token,
                        'Access-Control-Allow-Origin': '*',
                    }),
                responseType: 'blob',
                observe: 'response',
            };
        } else if (this.token != undefined) {
            return {
                headers: new HttpHeaders(
                    {
                        'X-Auth-Token': this.token,
                        'Access-Control-Allow-Origin': '*',
                    }),
                responseType: 'blob',
                observe: 'response',
            };
        } else if (localToken != undefined) {
            return {
                headers: new HttpHeaders(
                    {
                        'X-Auth-Token': localToken,
                        'Access-Control-Allow-Origin': '*',
                    }),
                responseType: 'blob',
                observe: 'response',
            };
        } else {
            return this.getHttpOptionsNoToken();
        }
    }

    public getHttpOptionsWithTokenBlobResponseHugeTimeout(token?: string): object {
        const localToken = localStorage.getItem('token');
        if (token != undefined) {
            return {
                headers: new HttpHeaders(
                    {
                        'X-Auth-Token': token,
                        'Access-Control-Allow-Origin': '*',
                        'timeout': '600',
                    }),
                responseType: 'blob',
                observe: 'response',
            };
        } else if (this.token != undefined) {
            return {
                headers: new HttpHeaders(
                    {
                        'X-Auth-Token': this.token,
                        'Access-Control-Allow-Origin': '*',
                        'timeout': '600',
                    }),
                responseType: 'blob',
                observe: 'response',
            };
        } else if (localToken != undefined) {
            return {
                headers: new HttpHeaders(
                    {
                        'X-Auth-Token': localToken,
                        'Access-Control-Allow-Origin': '*',
                        'timeout': '600',
                    }),
                responseType: 'blob',
                observe: 'response',
            };
        } else {
            return this.getHttpOptionsNoToken();
        }
    }

    public getHttpOptionsNoToken(): object {
        return {
            headers: new HttpHeaders(
                {
                    'Access-Control-Allow-Origin': '*',
                }),
        };
    }

    public setToken(token: string): void {
        this.token = token;
        localStorage.setItem('token', token);
    }

    public getToken(): string {
        if (this.token == undefined || this.token == '') {
            return localStorage.getItem('token');
        } else {
            return this.token;
        }
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";

@Component({
    selector: 'ngx-confirm-multiple',
    templateUrl: './confirm-multiple.component.html',
    styleUrls: ['./confirm-multiple.component.scss'],
})
export class ConfirmMultipleComponent implements OnInit {

    @Input()
    public amount: number = 0;

    constructor(
        protected dialogRef: NbDialogRef<any>
    ) { }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void { }

    public confirm(): void {
        this.dialogRef.close({delete: true});
    }

    public dismiss(): void {
        this.dialogRef.close();
    }

}

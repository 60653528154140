export class Project {

    public projectId: string;
    public userId: string;
    public templateId: string;
    public templateColorId: string;
    public childId: string;
    public currentState: string;
    public printableFileId: string;
    public createdAt: Date;
    public updatedAt: Date;

}
<div class="spinner" *ngIf="loading !== undefined && loading" [nbSpinner]="true">
</div>
<ng2-smart-table
    *ngIf="loading !== undefined && !loading"
    [settings]="settings"
    [source]="data"
    (custom)="onCustomAction($event)"
    (deleteConfirm)="onDeleteConfirm($event)"
    (createConfirm)="onAdd($event)"
    (editConfirm)="onEdit($event)"
    (userRowSelect)="onUserRowSelect($event)"
></ng2-smart-table>

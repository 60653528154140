import * as ROUTES from "./ROUTES";
import { Child } from "app/models/Child.model";
import { CreateChildRequest } from "app/models/requests/child/CreateChildRequest.model";
import { DataService } from './data.service';
import { GetAllChildrenResponse } from "app/models/response/child/GetAllChildrenResponse.model";
import { GetChildResponse } from "app/models/response/child/GetChildResponse.model";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdateChildRequest } from "app/models/requests/child/UpdateChildRequest.model";

@Injectable({
    providedIn: 'root',
})
export class ChildService {

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) {}

    // TODO: change return any to corresponding response
    public updateChild(child: Child): Observable<any> {
        return this.http.put(`${ROUTES.CHILD_ROUTE}/${child.childId}/`, new UpdateChildRequest(child),this.dataService.getHttpOptionsWithToken());
    }

    // TODO: change return any to corresponding response
    public deleteChild(childId: string): Observable<any> {
        return this.http.delete(`${ROUTES.CHILD_ROUTE}/${childId}/`, this.dataService.getHttpOptionsWithToken());
    }

    // TODO: change return any to corresponding response
    public createChild(child: Child): Observable<any> {
        return this.http.post(`${ROUTES.CHILD_ROUTE}/`, new CreateChildRequest(child), this.dataService.getHttpOptionsWithToken());
    }

    public getChildById(childId: string): Observable<GetChildResponse> {
        return this.http.get<GetChildResponse>(`${ROUTES.CHILD_ROUTE}/${childId}/`, this.dataService.getHttpOptionsWithToken());
    }

    public getAllChildren(): Observable<GetAllChildrenResponse> {
        return this.http.get<GetAllChildrenResponse>(`${ROUTES.CHILD_ROUTE}/all/`, this.dataService.getHttpOptionsWithToken());
    }


}
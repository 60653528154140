
export class User {

    public userId: string;
    public salutation: string;
    public lastName: string;
    public firstName: string;
    public street: string;
    public housenumber: string;
    public postal: string;
    public city: string;
    public country: string;
    public email: string;
    public phone: string;
    public password: string;
    public salt: string;
    public active: boolean;
    public admin: boolean;
    public createdAt: Date | any;
    public updatedAt: Date | any;

    public getCopyOfUser(): User {
        return Object.assign({}, this);
    }

}
import { Child } from "app/models/Child.model";

export class CreateChildRequest {

    public userId: string;
    public name: string;
    public groupName: string;
    public birthdate: string;
    public startYear: string;
    public endYear: string;

    constructor(child: Child) {
        this.userId = child.userId;
        this.name = child.name;
        this.groupName = child.groupName;
        this.birthdate = child.birthdate;
        this.startYear = child.startYear;
        this.endYear = child.endYear;
    }

    public toChild(child: Child): Child {
        if (child == null || child == undefined){
            child = new Child();
        }
        child.userId = this.userId;
        child.name = this.name;
        child.groupName = this.groupName;
        child.birthdate = this.birthdate;
        child.startYear = this.startYear;
        child.endYear = this.endYear;
        return child;
    }
}
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export class AutocompleteItem {
    display: string;
    filter: string;
    value: any;
}

@Component({
    selector: 'ngx-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit {

    @Input()
    @Output()
    public value: AutocompleteItem;

    @Input()
    public items: AutocompleteItem[];

    @Output()
    public onChange: EventEmitter<any> = new EventEmitter();
    @Output()
    public onSelect: EventEmitter<any> = new EventEmitter();

    public filterText: string;
    public filteredItems: AutocompleteItem[];
    public showItems: boolean;
    private looseFocus: boolean;

    // tslint:disable-next-line:no-empty
    constructor( ) { }

    public ngOnInit(): void {
        this.showItems = false;
        this.looseFocus = true;
        this.filterText = '';
        if (this.value !== undefined) {
            this.filterText = this.value.filter;
        }
        this.filter();
    }


    private filter(): void {
        this.filteredItems = [];
        this.items.forEach(x => {
            if (x.filter.toLowerCase().includes(this.filterText.toLowerCase()) && x !== this.value) {
                this.filteredItems.push(x);
            }
        });
        if (this.filteredItems.length == 0) this.setShowItems(false);
    }

    private setShowItems(value: boolean): void {
        if (this.looseFocus) {
            this.showItems = value;
        } else if (!this.looseFocus && !value) {
            this.looseFocus = true;
            return;
        }
    }

    public onFocus(): void {
        this.filter();
        this.setShowItems(true);
    }

    public onBlur(): void {
        this.setShowItems(false);
    }

    public onItemSelect(item: AutocompleteItem): void {
        this.value = item;
        this.filterText = this.value.filter;
        this.filter();
        this.setShowItems(false);
        this.onSelect.emit(this.value);
    }

    public onInputChange(): void {
        this.value = undefined;
        this.filter();
        this.setShowItems(true);
        this.onChange.emit(this.filterText);
    }

    public preventLooseFocus(): void {
        this.looseFocus = false;
    }

}

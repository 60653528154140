import { Component, Input, OnInit } from '@angular/core';
import { GetUserResponse } from 'app/models/response/user/GetUserResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NbDialogRef } from '@nebular/theme';
import { ToastService } from 'app/services/toast.service';
import { User } from 'app/models/User.model';
import { UserService } from 'app/services/user.service';

@Component({
    selector: 'ngx-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit {

    @Input()
    public user: User;
    @Input()
    public update: boolean;
    @Input()
    public edit: boolean;

    public userIsSelf: boolean;

    public newPassword: string;
    public newPasswordConfirm: string;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private userService: UserService
    ) { }

    public async ngOnInit(): Promise<void> {
        this.newPassword = '';
        this.newPasswordConfirm = '';
        await this.checkIfUserIsSelf();
    }

    private async checkIfUserIsSelf(): Promise<void> {
        await this.userService.getUserSelf().toPromise()
        .catch((err: HttpErrorResponse) => {
            // tslint:disable-next-line:no-console
            console.log(err);
        })
        .then((res: GetUserResponse) => {
            const user: User = res.body;
            if (user.userId == this.user.userId) {
                this.userIsSelf = true;
            }
        });
    }

    public submit(): void {
        // console.log(this.user);
        if (!this.dataIsValid()) {
            return;
        }
        if (this.newPassword != '') {
            this.user.password = this.newPassword;
        }
        if(!this.edit) {
            this.user.active = true;
        }
        this.dialogRef.close({
            save: this.update === undefined || this.update === false ? true : false,
            update: this.update,
            user: this.user,
        });
    }

    private dataIsValid(): boolean {
        if (
            this.user.email == '' ||
            this.user.firstName == '' ||
            this.user.lastName == ''
        ) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD, 4000);
            return false;
        } else if ((this.newPassword != '' || this.newPasswordConfirm != '') && this.newPassword !== this.newPasswordConfirm) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.PASSWORDS_DONT_MATCH, 4000);
            return false;
        }

        return true;
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

}

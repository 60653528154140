<nb-card>
    <nb-card-header>
        {{amount}} Eintr&auml;ge l&ouml;schen
    </nb-card-header>
    <nb-card-body>
        Sind Sie sicher, dass Sie {{amount}} Eintr&auml;ge l&ouml;schen m&ouml;chten?
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="confirm()">Ok</button>
        <button nbButton status="danger" (click)="dismiss()" style="float: right">Abbrechen</button>
    </nb-card-footer>
</nb-card>

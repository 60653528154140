<div class="">
    <div class="col-12 d-flex justify-content-center">
        <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
    </div>
</div>
<nb-card>
    <nb-card-header>
        <div class="row">
            <div class="col-sm-12">
                <h5 *ngIf="!edit">Template hinzuf&uuml;gen</h5>
                <h5 *ngIf="edit">Template bearbeiten</h5>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <form>
            <div class="row">
                <div class="col-12 mandatoryCol">
                    <span class="mandatory">*</span> Pflichtfeld
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-2 col-xl-2 label" for="templateName">Templatename <span class="mandatory">*</span></label>
                <div class="col-lg-4 col-xl-4">
                    <input id="templateName" name="templateName" placeholder="Templatename" [(ngModel)]="template.name" nbInput fullWidth>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 label" for="scaling">Skalierung <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input id="scaling" name="scaling" placeholder="Skalierung" [(ngModel)]="template.scalingFactor" nbInput fullWidth>
                </div>
                <label class="col-sm-2 label" for="mobileScaling">Skalierung (mobile) <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input id="mobileScaling" name="mobileScaling" placeholder="Skalierung (mobile)" [(ngModel)]="template.mobileScalingFactor" nbInput fullWidth>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 label" for="description">Beschreibung</label>
                <div class="col-sm-4">
                    <input id="description" name="description" placeholder="Beschreibung" [(ngModel)]="template.description" nbInput fullWidth>
                </div>
                <label class="col-sm-2 label" for="woocommerceProductId">ProduktID</label>
                <div class="col-sm-4">
                    <input id="woocommerceProductId" name="woocommerceProductId" placeholder="ProduktID" [(ngModel)]="template.woocommerceProductId" nbInput fullWidth>
                </div>
            </div>
        </form>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="d-inline-block mr-3">Templateareas</h5>
                            <i class="fas fa-plus hover-primary pointer" (click)="openTemplateArea()"></i>
                        </div>
                    </div>
                    <div class="row bb" *ngFor="let area of templateAreas; let i = index">
                        <div class="col-9 mb-2 mt-2">
                            {{i + 1}}. {{area.areaName}}, {{area.areaType}}, {{area.areaOrientation}}
                        </div>
                        <div class="col-3 d-flex justify-content-even actionCol mb-2 mt-2">
                            <i class="fas fa-pencil-alt pointer hover-primary" (click)="openTemplateArea(area)"></i>
                            <i class="fas fa-trash-alt pointer hover-red" (click)="deleteTemplateArea(area)"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="d-inline-block mr-3">Templatefarben</h5>
                            <i class="fas fa-plus pointer hover-primary" (click)="openTemplateColors()"></i>
                        </div>
                    </div>
                    <div class="row bb" *ngFor="let color of templateColors">
                        <div class="col-9 mb-2 mt-2">
                            {{color.name}}
                        </div>
                        <div class="col-3 d-flex justify-content-even actionCol mb-2 mt-2">
                            <i class="fas fa-pencil-alt pointer hover-primary" (click)="openTemplateColors(color)"></i>
                            <i class="fas fa-trash-alt pointer hover-red" (click)="deleteTemplateColor(color)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <div class="form-group row btnRow">
            <div class="col-sm-12">
                <button nbButton fullWidth (click)="submit()">Speichern</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>

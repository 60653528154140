import { Component, Input, OnInit } from '@angular/core';
import { AutocompleteItem } from 'app/components/autocomplete/autocomplete.component';
import { Child } from 'app/models/Child.model';
import { ChildService } from 'app/services/child.service';
import { GenericResponse } from 'app/models/response/GenericResponse.model';
import { GetAllChildrenResponse } from 'app/models/response/child/GetAllChildrenResponse.model';
import { GetAllUserResponse } from 'app/models/response/user/GetAllUserResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NbDialogRef } from '@nebular/theme';
import { Project } from 'app/models/Project.model';
import {ProjectService} from "../../../services/project.service";
import { Template } from 'app/models/Template.model';
import { TemplateColor } from 'app/models/TemplateColor.model';
import { TemplateService } from 'app/services/template.service';
import { ToastService } from 'app/services/toast.service';
import { User } from 'app/models/User.model';
import { UserService } from 'app/services/user.service';

@Component({
    selector: 'ngx-project-dialog',
    templateUrl: './project-dialog.component.html',
    styleUrls: ['./project-dialog.component.scss'],
})
export class ProjectDialogComponent implements OnInit {

    @Input()
    public update: boolean;
    @Input()
    public edit: boolean;
    @Input()
    public project: Project;

    private users: User[];
    private children: Child[];
    private templates: Template[];
    private templateColors: TemplateColor[];

    public autocompleteUsers: AutocompleteItem[];
    public autocompleteChildren: AutocompleteItem[];
    public autocompleteTemplates: AutocompleteItem[];
    public autocompleteTemplateColors: AutocompleteItem[];

    public selectedUserAutocomplete: AutocompleteItem;
    public selectedChildAutocomplete: AutocompleteItem;
    public selectedTemplateAutocomplete: AutocompleteItem;
    public selectedTemplateColorAutocomplete: AutocompleteItem;

    public selectedUser: User;
    public selectedChild: Child;
    public selectedTemplate: Template;
    public selectedTemplateColor: TemplateColor;

    public loading: boolean;
    public prePrintLoading: boolean = false;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private userService: UserService,
        private childService: ChildService,
        private templateService: TemplateService,
        private projectService: ProjectService
    ) { }

    public async ngOnInit(): Promise<void> {
        this.loading = true;
        await this.getUsers();
        await this.getChildren();
        await this.getTemplates();
        await this.getTemplateColors();
    }

    public submit(): void {
        if (!this.dataIsValid()) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD);
            return;
        }
        this.dialogRef.close({
            project: this.project,
            save: this.update === undefined || this.update === false ? true : false,
            update: this.update,
        });
    }

    private dataIsValid(): boolean {
        if (this.project.childId == undefined || this.project.childId == '') {
            return false;
        }
        if (this.project.userId == undefined || this.project.userId == '') {
            return false;
        }
        if (this.project.templateId == undefined || this.project.templateId == '') {
            return false;
        }
        if (this.project.templateColorId == undefined || this.project.templateColorId == '') {
            return false;
        }
        return true;
    }

    public onAutocompleteChange(event: string, type: string): void {
        // tslint:disable-next-line:switch-default
        switch(type) {
        case 'user':
            this.autocompleteUsers.forEach(x => {
                if (x.display.toLocaleLowerCase() == event.toLocaleLowerCase()) {
                    if(x.value != undefined && this.selectedUser.userId != x.value.userId){
                        this.selectedChild = undefined;
                        this.selectedChildAutocomplete = undefined;
                    }
                    this.selectedUser = x.value;
                    this.selectedUserAutocomplete = x;
                    this.project.userId = this.selectedUser.userId;
                    return;
                } else {
                    this.selectedUser = undefined;
                    this.selectedChild = undefined;
                    this.project.userId = undefined;
                    this.selectedUserAutocomplete = undefined;
                    this.selectedChildAutocomplete = undefined;
                }
            });
            this.setAutocompleteItems();
            break;
        case 'child':
            this.autocompleteChildren.forEach(x => {
                if (x.display.toLocaleLowerCase() == event.toLocaleLowerCase()) {
                    this.selectedChild = x.value;
                    this.selectedChildAutocomplete = x;
                    this.project.childId = this.selectedChild.childId;
                    return;
                } else {
                    this.selectedChild = undefined;
                    this.project.childId = undefined;
                    this.selectedChildAutocomplete = undefined;
                }
            });
            break;
        case 'template':
            this.autocompleteTemplates.forEach(x => {
                if (x.display.toLocaleLowerCase() == event.toLocaleLowerCase()) {
                    this.selectedTemplate = x.value;
                    this.selectedTemplateAutocomplete = x;
                    this.project.templateId = this.selectedTemplate.templateId;
                    return;
                } else {
                    this.selectedTemplate = undefined;
                    this.project.templateId = undefined;
                    this.selectedTemplateAutocomplete = undefined;
                }
            });
            break;
        case 'templatecolor':
            this.autocompleteTemplateColors.forEach(x => {
                if (x.display.toLocaleLowerCase() == event.toLocaleLowerCase()) {
                    this.selectedTemplateColor = x.value;
                    this.selectedTemplateColorAutocomplete = x;
                    this.project.templateColorId = this.selectedTemplateColor.templateColorId;
                    return;
                } else {
                    this.selectedTemplateColor = undefined;
                    this.project.templateColorId = undefined;
                    this.selectedTemplateColorAutocomplete = undefined;
                }
            });
            break;
        }
    }

    public onAutocompleteSelect(event: AutocompleteItem, type: string): void {
        // tslint:disable-next-line:switch-default
        switch(type) {
        case 'user':
            if(this.selectedUser == undefined){
                this.selectedChild = undefined;
                this.selectedChildAutocomplete = undefined;
            }
            if(event.value != undefined && this.selectedUser != undefined && this.selectedUser.userId != event.value.userId){
                this.selectedChild = undefined;
                this.selectedChildAutocomplete = undefined;
            }
            this.selectedUser = event.value;
            this.project.userId = this.selectedUser.userId;
            this.selectedUserAutocomplete = event;
            this.setAutocompleteItems();
            break;
        case 'child':
            this.selectedChild = event.value;
            this.project.childId = this.selectedChild.childId;
            this.selectedChildAutocomplete = event;
            break;
        case 'template':
            this.selectedTemplate = event.value;
            this.project.templateId = this.selectedTemplate.templateId;
            this.selectedTemplateAutocomplete = event;
            this.getTemplateColors();
            break;
        case 'templatecolor':
            this.selectedTemplateColor = event.value;
            this.project.templateColorId = this.selectedTemplateColor.templateColorId;
            this.selectedTemplateColorAutocomplete = event;
            break;
        }
    }

    private async getUsers(): Promise<void> {
        await this.userService.getAllUsers().toPromise()
        .catch((err: HttpErrorResponse) => {
            // tslint:disable-next-line:no-console
            console.log(err);
        })
        .then((res: GetAllUserResponse) => {
            if (res == undefined) return;
            if (res.status == 200) {
                this.users = res.body;
                this.setAutocompleteItems();
            }
        });
    }

    private async getChildren(): Promise<void> {
        await this.childService.getAllChildren().toPromise()
        .catch((err: HttpErrorResponse) => {
            // tslint:disable-next-line:no-console
            console.log(err);
        })
        .then((res: GetAllChildrenResponse) => {
            if (res == undefined) return;
            if (res.status == 200) {
                this.children = res.body;
                this.setAutocompleteItems();
            }
        });
    }

    private async getTemplates(): Promise<void> {
        await this.templateService.getAll().toPromise()
        .catch((err: HttpErrorResponse) => {
            // tslint:disable-next-line:no-console
            console.log(err);
        })
        .then((res: GenericResponse<Template[]>) => {
            if (res == undefined) return;
            if (res.status == 200) {
                this.templates = res.body;
                this.setAutocompleteItems();
            }
        });
    }

    private async getTemplateColors(): Promise<void> {
        if (this.selectedTemplate == undefined) {
            this.setAutocompleteItems(true);
            return;
        }
        await this.templateService.getAllTemplateColors(this.selectedTemplate.templateId).toPromise()
        .catch((err: HttpErrorResponse) => {
            // tslint:disable-next-line:no-console
            console.log(err);
        })
        .then((res: GenericResponse<TemplateColor[]>) => {
            if (res == undefined) return;
            if (res.status == 200) {
                this.templateColors = res.body;
                this.setAutocompleteItems(true);
            }
        });
    }

    private setAutocompleteItems(stopLoading?: boolean): void {
        if (this.users != undefined) {
            const temp: AutocompleteItem[] = [];
            this.users.forEach(x => {
                temp.push({display: `${x.email}`, filter: `${x.email}`, value: x});
                if (this.project != undefined && this.project.userId == x.userId) {
                    this.selectedUserAutocomplete = temp[temp.length - 1];
                    this.selectedUser = x;
                }
            });
            this.autocompleteUsers = temp;
        } else {
            this.autocompleteUsers = [];
        }

        if (this.children != undefined && this.selectedUser != undefined) {
            const temp: AutocompleteItem[] = [];
            this.children.forEach(x => {
                if(x.userId == this.selectedUser.userId){
                    temp.push({display: `${x.name}`, filter: `${x.name}`, value: x});
                    if (this.project != undefined && this.project.childId == x.childId) {
                        this.selectedChildAutocomplete = temp[temp.length - 1];
                        this.selectedChild = x;
                    }
                }
            });
            this.autocompleteChildren = temp;
        } else {
            this.autocompleteChildren = [];
        }

        if (this.templates != undefined) {
            const temp: AutocompleteItem[] = [];
            this.templates.forEach(x => {
                temp.push({display: `${x.name}`, filter: `${x.name}`, value: x});
                if (this.project != undefined && this.project.templateId == x.templateId) {
                    this.selectedTemplateAutocomplete = temp[temp.length - 1];
                    this.selectedTemplate = x;
                }
            });
            this.autocompleteTemplates = temp;
        } else {
            this.autocompleteTemplates = [];
        }

        if (this.templateColors != undefined) {
            const temp: AutocompleteItem[] = [];
            this.templateColors.forEach(x => {
                temp.push({display: `${x.name}`, filter: `${x.name}`, value: x});
                if (this.project != undefined && this.project.templateColorId == x.templateColorId) {
                    this.selectedTemplateColorAutocomplete = temp[temp.length - 1];
                    this.selectedTemplateColor = x;
                }
            });
            this.autocompleteTemplateColors = temp;
        } else {
            this.autocompleteTemplateColors = [];
        }

        if (stopLoading) {
            this.loading = false;
        }

    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

    public startPrint(): void{
        this.projectService.triggerPrintCreation(this.project.projectId).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.PRINT_FILE_FAILED, 10000, err);
            },
            next: (res: GenericResponse<Project>) => {
                this.project = res.body;
            },
        });
    }

    public download(): void{

        this.projectService.getFile(this.project.printableFileId).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
            },
            next: value => {
                this.downLoadFile(value.body, value.headers.get('correct-filename'));
            },
        });
    }

    public prePrint(): void{
        this.prePrintLoading = true;
        this.projectService.getPreprint(this.project.projectId).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                this.prePrintLoading = false;
            },
            next: value => {
                this.downLoadFile(value.body, value.headers.get('correct-filename'));
                this.prePrintLoading = false;
            },
        });
    }

    private downLoadFile(data: any, name: string): void{
        let blob = new Blob([data], { type: 'application/octet-stream'});
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.setAttribute('type','hidden');
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

}

<div class="">
    <div class="col-12 d-flex justify-content-center">
        <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
    </div>
</div>
<nb-card>
    <nb-card-header>
        <div class="row">
            <div class="col-sm-12">
                <h5 *ngIf="!edit">Templatearea hinzuf&uuml;gen</h5>
                <h5 *ngIf="edit">Templatearea bearbeiten</h5>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <form>
            <div class="row">
                <div class="col-12 mandatoryCol">
                    <span class="mandatory">*</span> Pflichtfeld
                </div>
            </div>

            <div class="form-group row">
                <label class="col-lg-2 col-xl-2 label" for="areaName">Name <span class="mandatory">*</span></label>
                <div class="col-lg-4 col-xl-4">
                    <input nbInput fullWidth type="text" class="w-100" name="areaName" id="areaName" [(ngModel)]="templateArea.areaName" [placeholder]="'Name'">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-lg-2 col-xl-2 label" for="areatype">Areatyp <span class="mandatory">*</span></label>
                <div class="col-lg-4 col-xl-4">
                    <nb-select class="w-100" name="areaType" id="areaType" [(ngModel)]="templateArea.areaType" placeholder="Areatyp">
                        <nb-option [value]="'text'">Text</nb-option>
                        <nb-option [value]="'static.text'">Statischer Text</nb-option>
                        <nb-option [value]="'image'">Bild</nb-option>
                    </nb-select>
                </div>
                <label class="col-sm-2 label" for="orientation">Orientierung <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <nb-select class="w-100" name="orientation" id="orientation" [(ngModel)]="templateArea.areaOrientation" placeholder="Orientierung">
                        <nb-option [value]="'horizontal'">Horizontal</nb-option>
                        <nb-option [value]="'vertical'">Vertikal</nb-option>
                    </nb-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="templateArea.areaType != undefined && templateArea.areaType == 'image'">
                <label class="col-sm-2 label mb-2" for="notPrintable">Nicht Druckbar</label>
                <div class="col-sm-4 mb-2">
                    <nb-checkbox name="notPrintable" id="notPrintable" [(ngModel)]="templateArea.notPrinted"></nb-checkbox>
                </div>
                <label class="col-sm-2 label mb-2" for="alwaysForeground">Immer Vordergrund</label>
                <div class="col-sm-4 mb-2">
                    <nb-checkbox name="alwaysForeground" id="alwaysForeground" [(ngModel)]="templateArea.alwaysForeground"></nb-checkbox>
                </div>
            </div>

            <div class="form-group row" *ngIf="templateArea.areaType != undefined && templateArea.areaType == 'text'">
                <label class="col-sm-2 label mb-2" for="contentDefinition">Content <span class="mandatory">*</span></label>
                <div class="col-sm-4 mb-2">
                    <nb-select class="w-100" style="max-width: 100%;" name="contentDefinition" id="contentDefinition" [(ngModel)]="templateArea.areaContentDefinition" [placeholder]="contentDefinitions.length < 1 ? 'loading...' : 'Content'">
                        <nb-option *ngFor="let content of contentDefinitions" [value]="content.key">{{content.name}}</nb-option>
                    </nb-select>
                </div>
                <label class="col-sm-2 label mb-2" for="textSize">
                    <abbr title="Wenn Größe leer oder 0 wird der Text in den über die Koordinaten vorgegebenen Kasten gepasst." style="cursor: help">Gr&ouml;&szlig;e</abbr>
                </label>
                <div class="col-sm-4 mb-2">
                    <input nbInput fullWidth type="number" class="w-100" name="textSize" id="textSize" [(ngModel)]="templateArea.staticTextSize" [placeholder]="'Größe'">
                </div>
                <label class="col-sm-2 label mb-2" for="textColor">Farbe</label>
                <div class="col-sm-4 mb-2">
                    <input nbInput fullWidth type="text" class="w-100" name="textColor" id="textColor" [(ngModel)]="templateArea.staticTextColor" [placeholder]="'Farbe'">
                </div>
            </div>

            <div class="form-group row" *ngIf="templateArea.areaType != undefined && templateArea.areaType == 'static.text'">
                <label class="col-sm-2 label mb-2" for="staticContent">Content <span class="mandatory">*</span></label>
                <div class="col-sm-4 mb-2">
                    <input nbInput fullWidth type="text" class="w-100" name="staticContent" id="staticContent" [(ngModel)]="templateArea.staticTextContent" [placeholder]="'Content'">
                </div>
                <label class="col-sm-2 label mb-2" for="staticTextSize">Gr&ouml;&szlig;e <span class="mandatory">*</span></label>
                <div class="col-sm-4 mb-2">
                    <input nbInput fullWidth type="number" class="w-100" name="staticTextSize" id="staticTextSize" [(ngModel)]="templateArea.staticTextSize" [placeholder]="'Größe'">
                </div>
                <label class="col-sm-2 label mb-2" for="staticTextColor">Farbe <span class="mandatory">*</span></label>
                <div class="col-sm-4 mb-2">
                    <input nbInput fullWidth type="text" class="w-100" name="staticTextColor" id="staticTextColor" [(ngModel)]="templateArea.staticTextColor" [placeholder]="'Farbe'">
                </div>
                <label class="col-sm-2 label mb-2" for="staticTextEditable">Bearbeitbar</label>
                <div class="col-sm-4 mb-2">
                    <nb-checkbox name="staticTextEditable" id="staticTextEditable" [(ngModel)]="templateArea.staticTextEditable"></nb-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4>Koordinaten:</h4>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 label" for="tlx">Oben-Links X <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input id="tlx" name="tlx" type="number" min="0" [(ngModel)]="templateArea.coordTopLeftX" nbInput fullWidth>
                </div>
                <label class="col-sm-2 label" for="tly">Oben-Links Y <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input id="tly" name="tly" type="number" min="0" [(ngModel)]="templateArea.coordTopLeftY" nbInput fullWidth>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 label" for="brx">Unten-Rechts X <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input id="brx" name="brx" type="number" min="0" [(ngModel)]="templateArea.coordBottomRightX" nbInput fullWidth>
                </div>
                <label class="col-sm-2 label" for="bry">Unten-Rechts Y <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <input id="bry" name="bry" type="number" min="0" [(ngModel)]="templateArea.coordBottomRightY" nbInput fullWidth>
                </div>
            </div>

            <div class="row" *ngIf="templateArea.areaType != undefined && templateArea.areaType == 'image'">
                <div class="col-12">
                    <h4>Platzhalter:</h4>
                </div>
            </div>

            <div class="row d-flex align-items-center" *ngIf="templateArea.areaType != undefined && templateArea.areaType == 'image'">
                <div class="col-4">
                    <input type="file" (change)="fileInputChange($event)">
                </div>
                <div class="col-4" *ngIf="templateArea.imageData != undefined && templateArea.imageData.length > 0">
                    <img [src]="templateArea.imageData" alt="" style="max-height: 100px;">
                </div>
                <div class="col-4" *ngIf="templateArea.imageData != undefined && templateArea.imageData.length > 0">
                    <button nbButton fullWidth (click)="templateArea.imageData = ''">Bild L&ouml;schen</button>
                </div>
            </div>

        </form>
    </nb-card-body>
    <nb-card-footer>
        <div class="form-group row btnRow">
            <div class="col-sm-12">
                <button nbButton fullWidth (click)="submit()">Speichern</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>

export class Child {

    public childId: string;
    public userId: string;
    public name: string;
    public groupName: string;
    public birthdate: string;
    public startYear: string;
    public endYear: string;
    public createdAt: Date | any;
    public updateddAt: Date | any;

    public getCopyOfChild(): Child {
        return Object.assign({}, this);
    }

}
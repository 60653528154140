import {Component, Input, OnInit} from '@angular/core';
import {FileSystemFileEntry} from "ngx-file-drop";
import {NbDialogRef} from "@nebular/theme";
import {TemplateColor} from "../../../models/TemplateColor.model";
import {TemplateService} from "../../../services/template.service";
import {ToastService} from "../../../services/toast.service";

@Component({
    selector: 'ngx-template-color-component',
    templateUrl: './template-color-component.component.html',
    styleUrls: ['./template-color-component.component.scss'],
})
export class TemplateColorComponentComponent implements OnInit {

    @Input() templateId: string;
    @Input() templateColor: TemplateColor;
    @Input() edit: boolean;

    public printFile: any;
    public printFileBlob: any;
    public printName: string;
    public editFile: any;
    public editFileBlob: any;
    public editName: string;
    public thumbnailFile: any;
    public thumbnailFileBlob: any;
    public thumbnailName: string;
    public mobileFile: any;
    public mobileFileBlob: any;
    public mobileName: string;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private templateService: TemplateService
    ) { }

    async ngOnInit(): Promise<void> {
        if(this.templateColor == undefined){
            this.templateColor = new TemplateColor();
            this.templateColor.templateId = this.templateId;
        }else{
            this.templateService.getFileName(this.templateColor.printingFileId).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                },
                next: value => {
                    this.printName = value.body;
                },
            });
            this.templateService.getFileName(this.templateColor.showingFileId).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                },
                next: value => {
                    this.editName = value.body;
                },
            });
            this.templateService.getFileName(this.templateColor.thumbnailFileId).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                },
                next: value => {
                    this.thumbnailName = value.body;
                },
            });
            this.templateService.getFileName(this.templateColor.mobileShowingFileId).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                },
                next: value => {
                    this.mobileName = value.body;
                },
            });
        }
    }

    public download(type: string): void{
        if(type == 'print'){
            this.templateService.getFile(this.templateColor.printingFileId).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                },
                next: value => {
                    this.printFileBlob = value.body;
                    this.printName = value.headers.get('correct-filename');
                    this.downLoadFile(type);
                },
            });
        }else if(type == 'edit'){
            this.templateService.getFile(this.templateColor.showingFileId).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                },
                next: value => {
                    this.editFileBlob = value.body;
                    this.editName = value.headers.get('correct-filename');
                    this.downLoadFile(type);
                },
            });
        }else if(type == 'mobile'){
            this.templateService.getFile(this.templateColor.mobileShowingFileId).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                },
                next: value => {
                    this.mobileFileBlob = value.body;
                    this.mobileName = value.headers.get('correct-filename');
                    this.downLoadFile(type);
                },
            });
        }else{
            this.templateService.getFile(this.templateColor.thumbnailFileId).subscribe({
                error: err => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOADING_DATA_FAILED, 10000, err);
                },
                next: value => {
                    this.thumbnailFileBlob = value.body;
                    this.thumbnailName = value.headers.get('correct-filename');
                    this.downLoadFile(type);
                },
            });
        }
    }

    public removeImage(type: string){
        if(type == 'print'){
            this.printFileBlob = undefined;
            this.printName = undefined;
            this.printFile = undefined;
            this.templateColor.printingFileId = null;
        }else if(type == 'edit'){
            this.editFileBlob = undefined;
            this.editName = undefined;
            this.editFile = undefined;
            this.templateColor.showingFileId = null;
        }else if(type == 'mobile'){
            this.mobileFileBlob = undefined;
            this.mobileName = undefined;
            this.mobileFile = undefined;
            this.templateColor.mobileShowingFileId = null;
        }else{
            this.thumbnailFileBlob = undefined;
            this.thumbnailName = undefined;
            this.thumbnailFile = undefined;
            this.templateColor.thumbnailFileId = null;
        }
    }

    private downLoadFile(type: string): void{
        let data; let name;
        if(type == 'print'){
            data = this.printFileBlob;
            name = this.printName;
        }else if(type == 'edit'){
            data = this.editFileBlob;
            name = this.editName;
        }else if(type == 'mobile'){
            data = this.mobileFileBlob;
            name = this.mobileName;
        }else{
            data = this.thumbnailFileBlob;
            name = this.thumbnailName;
        }
        let blob = new Blob([data], { type: 'application/octet-stream'});
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.setAttribute('type','hidden');
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    public async submit(): Promise<void> {
        if (!this.dataIsValid(this.edit)) {
            return;
        }
        let isValid = true;
        if(this.editFile != undefined){
            await this.templateService.upload(this.editFile).toPromise().then((res: { id: string }) => {
                this.templateColor.showingFileId = res.id;
            }).catch(err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                // TODO: Add error toast
                isValid = false;
            });
        }
        if(this.printFile != undefined){
            await this.templateService.upload(this.printFile).toPromise().then((res: { id: string }) => {
                this.templateColor.printingFileId = res.id;
            }).catch(err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                // TODO: Add error toast
                isValid = false;
            });
        }
        if(this.thumbnailFile != undefined){
            await this.templateService.upload(this.thumbnailFile).toPromise().then((res: { id: string }) => {
                this.templateColor.thumbnailFileId = res.id;
            }).catch(err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                // TODO: Add error toast
                isValid = false;
            });
        }
        if(this.mobileFile != undefined){
            await this.templateService.upload(this.mobileFile).toPromise().then((res: { id: string }) => {
                this.templateColor.mobileShowingFileId = res.id;
            }).catch(err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                // TODO: Add error toast
                isValid = false;
            });
        }
        if(isValid){
            this.dialogRef.close({
                save: !this.edit,
                color: this.templateColor,
            });
        }
    }

    private dataIsValid(isEdit = false): boolean {
        if (
            this.templateColor.name == '' || this.templateColor.name == undefined ||
            (this.editFile == undefined && (this.editName == undefined || this.editName.length < 1)) ||
            (this.printFile == undefined && (this.printName == undefined || this.printName.length < 1)) ||
            (this.thumbnailFile == undefined &&(this.thumbnailName == undefined || this.thumbnailName.length < 1))
        ) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD, 4000);
            return false;
        }

        return true;
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

    public dropped(event: any, type: string): void{
        let data = [];
        event.forEach(drop => {
            data.push(drop.fileEntry);
        });
        for (let i = 0; i < data.length; i++) {
            if (data[i].isFile) {
                const fileEntry = data[i] as FileSystemFileEntry;
                fileEntry.file((f: File) => {
                    if(type == 'edit'){
                        this.editFile = f;
                    }else if(type == 'print'){
                        this.printFile = f;
                    }else if(type == 'mobile'){
                        this.mobileFile = f;
                    }else{
                        this.thumbnailFile = f;
                    }
                });
            }
        }
    }

}

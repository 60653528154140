import {Component, Input, OnInit} from '@angular/core';
import {ProjectState} from "../../../models/ProjectState.model";
import {ViewCell} from "ng2-smart-table";

@Component({
    template: `
        <button nbButton outline status="{{status}}" [disabled]="status == ''">
            {{value.name != '' && value.name != undefined ? value.name : 'missing status'}}
        </button>
    `,
})
export class ProjectStatusColComponent implements ViewCell, OnInit {
    @Input() value: any;    // This hold the cell value
    @Input() rowData: any;  // This holds the entire row object

    public status: string = '';

    ngOnInit(): void {
        switch (this.value.key){
        case "project.state.in.progress":
            this.status = 'warning';
            break;
        case "project.state.ready.to.print":
            this.status = 'success';
            break;
        case "project.state.dispatched":
            this.status = 'info';
            break;
        case "project.state.final":
            this.status = 'primary';
            break;
        default:
            this.status = '';
            break;
        }
    }

}

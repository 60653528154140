import {Component, Input, OnInit} from '@angular/core';
import {AreaContentDefinition} from "../../../models/AreaContentDefinition.model";
import {GenericResponse} from "../../../models/response/GenericResponse.model";
import {NbDialogRef} from "@nebular/theme";
import {TemplateArea} from "../../../models/TemplateArea.model";
import {TemplateService} from "../../../services/template.service";
import {ToastService} from "../../../services/toast.service";

@Component({
    selector: 'ngx-template-area-component',
    templateUrl: './template-area-component.component.html',
    styleUrls: ['./template-area-component.component.scss'],
})
export class TemplateAreaComponentComponent implements OnInit {

    @Input() templateArea: TemplateArea;
    @Input() templateId: string;
    @Input() edit: boolean;

    public contentDefinitions: AreaContentDefinition[];

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private templateService: TemplateService
    ) { }

    ngOnInit(): void {
        this.contentDefinitions = [];
        if(this.templateArea == undefined){
            this.templateArea = new TemplateArea();
            this.templateArea.templateId = this.templateId;
        }
        this.templateService.getAllAreaContentDefinition().subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger,this.toastService.LOADING_DATA_FAILED, 10000,err);
            },
            next: (value: GenericResponse<AreaContentDefinition[]>) => {
                this.contentDefinitions = value.body;
            },
        });
    }

    public submit(): void {
        if (!this.dataIsValid()) {
            return;
        }
        if(this.templateArea.areaType == 'image'){
            this.templateArea.areaContentDefinition = 'img';
        }
        this.dialogRef.close({
            save: !this.edit,
            area: this.templateArea,
        });
    }

    private dataIsValid(): boolean {
        if (
            this.templateArea.areaType == '' || this.templateArea.areaType == undefined ||
            this.templateArea.areaOrientation == '' || this.templateArea.areaOrientation == undefined ||
            this.templateArea.coordTopLeftX < 0 || this.templateArea.coordTopLeftX == undefined ||
            this.templateArea.coordTopLeftY < 0 || this.templateArea.coordTopLeftY == undefined ||
            this.templateArea.coordBottomRightX < 0 || this.templateArea.coordBottomRightX == undefined ||
            this.templateArea.coordBottomRightY < 0 || this.templateArea.coordBottomRightY == undefined ||
            this.templateArea.areaName == undefined || this.templateArea.areaName == ''
        ) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD, 4000);
            return false;
        }

        return true;
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

    public fileInputChange(event: any): void{
        let data: FileList = event.target.files;
        for (let i = 0; i < data.length; i++) {
            if (data.item(i) != undefined) {
                let file = data.item(i);
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = (() => {
                    this.templateArea.imageData = typeof reader.result == "string" ? reader.result : '';
                });
            }
        }
    }
}

import { User } from "../../User.model";

export class CreateUserRequest {

    public salutation: string;
    public lastName: string;
    public firstName: string;
    public street: string;
    public housenumber: string;
    public postal: string;
    public city: string;
    public country: string;
    public email: string;
    public phone: string;
    public updatePassword: string;
    public admin: boolean;
    public active: boolean;

    constructor(user: User) {
        this.active = user.active;
        this.salutation = user.salutation;
        this.lastName = user.lastName;
        this.firstName = user.firstName;
        this.street = user.street;
        this.housenumber = user.housenumber;
        this.postal = user.postal;
        this.city = user.city;
        this.country = user.country;
        this.email = user.email;
        this.phone = user.phone;
        this.admin = user.admin;
        this.updatePassword = user.password;
    }

    public toUser(user: User): User {
        if (user == null || user == undefined){
            user = new User();
        }
        user.active = this.active;
        user.salutation = this.salutation;
        user.lastName = this.lastName;
        user.firstName = this.firstName;
        user.street = this.street;
        user.housenumber = this.housenumber;
        user.postal = this.postal;
        user.city = this.city;
        user.country = this.country;
        user.email = this.email;
        user.phone = this.phone;
        user.admin = this.admin;
        return user;
    }
}
<div class="">
    <div class="col-12 d-flex justify-content-center">
        <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
    </div>
</div>
<nb-card>
    <nb-card-header>
        <div class="row">
            <div class="col-sm-12">
                <h5 *ngIf="!edit">Templatefarbe hinzuf&uuml;gen</h5>
                <h5 *ngIf="edit">Templatefarbe bearbeiten</h5>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <form>
            <div class="row">
                <div class="col-12 mandatoryCol">
                    <span class="mandatory">*</span> Pflichtfeld
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-xl-3 label" for="colorName">Name <span class="mandatory">*</span></label>
                <div class="col-lg-9 col-xl-9">
                    <input id="colorName" name="colorName" placeholder="Name" [(ngModel)]="templateColor.name" nbInput fullWidth>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-3">
                <h5>Thumbnaildatei</h5>
            </div>
            <div class="col-3">
                <h5>Bearbeitungsdatei</h5>
            </div>
            <div class="col-3">
                <h5>Bearbeitungsdatei (mobile)</h5>
            </div>
            <div class="col-3">
                <h5>Druckdatei</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-3" *ngIf="templateColor.thumbnailFileId == undefined">
                <ngx-file-drop dropZoneLabel="{{thumbnailFile == undefined?'Datei hier ablegen. *':thumbnailFile.name}}" (onFileDrop)="dropped($event, 'thumbnail')"></ngx-file-drop>
            </div>
            <div class="col-3 d-flex justify-content-between" *ngIf="thumbnailName != undefined">
                <div>
                    <span style="display: block">{{templateColor.thumbnailFileId}}</span>
                    <span>{{thumbnailName}}</span>
                </div>
                <span>
                    <i class="fas fa-download pointer hover-primary mr-4" (click)="download('thumbnail')"></i>
                    <i class="fas fa-trash-alt pointer hover-red" (click)="removeImage('thumbnail')"></i>
                </span>
            </div>




            <div class="col-3" *ngIf="templateColor.showingFileId == undefined">
                <ngx-file-drop dropZoneLabel="{{editFile == undefined?'Datei hier ablegen. *':editFile.name}}" (onFileDrop)="dropped($event, 'edit')"></ngx-file-drop>
            </div>
            <div class="col-3 d-flex justify-content-between" *ngIf="editName != undefined">
                <div>
                    <span style="display: block">{{templateColor.showingFileId}}</span>
                    <span>{{editName}}</span>
                </div>
                <span>
                    <i class="fas fa-download pointer hover-primary mr-4" (click)="download('edit')"></i>
                    <i class="fas fa-trash-alt pointer hover-red" (click)="removeImage('edit')"></i>
                </span>
            </div>



            <div class="col-3" *ngIf="templateColor.mobileShowingFileId == undefined">
                <ngx-file-drop dropZoneLabel="{{mobileFile == undefined?'Datei hier ablegen. *':mobileFile.name}}" (onFileDrop)="dropped($event, 'mobile')"></ngx-file-drop>
            </div>
            <div class="col-3 d-flex justify-content-between" *ngIf="mobileName != undefined">
                <div>
                    <span style="display: block">{{templateColor.mobileShowingFileId}}</span>
                    <span>{{mobileName}}</span>
                </div>
                <span>
                    <i class="fas fa-download pointer hover-primary mr-4" (click)="download('mobile')"></i>
                    <i class="fas fa-trash-alt pointer hover-red" (click)="removeImage('mobile')"></i>
                </span>
            </div>


            <div class="col-3" *ngIf="templateColor.printingFileId == undefined">
                <ngx-file-drop dropZoneLabel="{{printFile == undefined?'Datei hier ablegen. *':printFile.name}}" (onFileDrop)="dropped($event, 'print')"></ngx-file-drop>
            </div>
            <div class="col-3 d-flex justify-content-between" *ngIf="printName != undefined">
                <div>
                    <span style="display: block">{{templateColor.printingFileId}}</span>
                    <span>{{printName}}</span>
                </div>
                <span>
                    <i class="fas fa-download pointer hover-primary mr-4" (click)="download('print')"></i>
                    <i class="fas fa-trash-alt pointer hover-red" (click)="removeImage('print')"></i>
                </span>
            </div>


        </div>
    </nb-card-body>
    <nb-card-footer>
        <div class="form-group row btnRow">
            <div class="col-sm-12">
                <button nbButton fullWidth (click)="submit()">Speichern</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>

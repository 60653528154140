import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'app/services/toast.service';
import { UserService } from 'app/services/user.service';

@Component({
    selector: 'ngx-reset-password',
    styleUrls: ['./reset-password.component.scss'],
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {

    public password: string;
    public confirmPassword: string;

    private token: string;

    constructor(
        private userService: UserService,
        private toastService: ToastService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    public ngOnInit(): void {
        this.token = this.route.snapshot.params.token;
    }

    public async resetPassword(): Promise<void> {
        if (this.dataIsValid()) {
            await this.userService.resetPasswordConfirm(this.password, this.token).toPromise()
            .catch((err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.RESET_PASSWORD_FAILED);
            })
            // TODO: Response Type angeben
            .then(res => {
                if (res == undefined) return;
                if (res.status == 200) {
                    this.router.navigate(['login'], {state: {resetPWConfirmSuccess: true}});
                }
            });
        }
    }

    private dataIsValid(): boolean {
        if (this.password == undefined || this.password == '') {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD);
            return false;
        }
        if (this.confirmPassword == undefined || this.confirmPassword == '') {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD);
            return false;
        }
        if (this.password != this.confirmPassword) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.PASSWORDS_DONT_MATCH, 3000);
            return false;
        }
        return true;
    }

}

<div
    class="container"
>
    <input
        class="input"
        nbInput
        [(ngModel)]="filterText"
        (keyup)="onInputChange()"
        (blur)="onBlur()"
        (focus)="onFocus()"
    >
    <div
        class="item-container"
        *ngIf="showItems"
        (mouseover)="preventLooseFocus()"
    >
        <div
            *ngFor="let item of filteredItems"
            class="item"
            (click)="onItemSelect(item)"
        >
            {{ item.display }}
        </div>
    </div>
</div>
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ConfirmComponent } from './confirm/confirm.component';
import { NbDialogService } from '@nebular/theme';

@Component({
    selector: 'ngx-smart-table',
    templateUrl: './smart-table.component.html',
    styleUrls: ['./smart-table.component.scss'],
})
export class SmartTableComponent implements OnInit {

    @Input() public settings: object;
    @Input() public data: any[];
    @Input() public loading: boolean;
    @Input() public shouldConfirmDelete: boolean = true;
    @Output() deleteConfirm: EventEmitter<any> = new EventEmitter();
    @Output() createConfirm: EventEmitter<any> = new EventEmitter();
    @Output() editConfirm: EventEmitter<any> = new EventEmitter();
    @Output() custom: EventEmitter<any> = new EventEmitter();
    @Output() onRowSelect: EventEmitter<any> = new EventEmitter();

    constructor(
        private dialogService: NbDialogService
    ) { }

    // tslint:disable-next-line:no-empty
    ngOnInit(): void { }


    public onDeleteConfirm(event): void {
        if (!this.shouldConfirmDelete) {
            this.deleteConfirm.emit(event);
            return;
        }
        const modal = this.dialogService.open(ConfirmComponent);
        modal.onClose.subscribe({
            next: response => {
                if (response !== undefined && response.delete) {
                    this.deleteConfirm.emit(event);
                } else {
                    event.confirm.reject();
                }
            },
        });
    }

    public onAdd(event: any): void{
        this.createConfirm.emit(event);
    }

    public onEdit(event: any): void{
        this.editConfirm.emit(event);
    }

    public onCustomAction(event: any): void {
        this.custom.emit(event);
    }

    public onUserRowSelect(event: any): void{
        this.onRowSelect.emit(event);
    }

}

export class TemplateArea{

    public areaId: string;
    public templateId: string;
    public areaType: string;
    public areaOrientation: string;
    public areaContentDefinition: string;
    public coordTopLeftX: number = 0;
    public coordTopLeftY: number = 0;
    public coordBottomRightX: number = 0;
    public coordBottomRightY: number = 0;
    public createdAt: Date;
    public updatedAt: Date;
    public staticTextContent: string = '';
    public staticTextSize: number = 16;
    public staticTextColor: string = '#ffffff';
    public staticTextEditable: boolean = false;
    public imageData: string;
    public areaName: string;
    public notPrinted: boolean = false;
    public alwaysForeground: boolean = false;
}

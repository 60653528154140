import {Component, Input, OnInit} from "@angular/core";
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {ConfirmComponent} from "../../smart-table/confirm/confirm.component";
import {GenericResponse} from "../../../models/response/GenericResponse.model";
import {Template} from "../../../models/Template.model";
import {TemplateArea} from "../../../models/TemplateArea.model";
import {TemplateAreaComponentComponent} from "../template-area-component/template-area-component.component";
import {TemplateColor} from "../../../models/TemplateColor.model";
import {TemplateColorComponentComponent} from "../template-color-component/template-color-component.component";
import {TemplateService} from "../../../services/template.service";
import {ToastService} from "../../../services/toast.service";

@Component({
    selector: 'ngx-template-dialog',
    templateUrl: './template-dialog.component.html',
    styleUrls: ['./template-dialog.component.scss'],
})
export class TemplateDialogComponent implements OnInit {

    @Input()
    public template: Template;
    @Input()
    public edit: boolean;
    public templateAreas: TemplateArea[];
    public templateColors: TemplateColor[];

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private modalController: NbDialogService,
        private toastService: ToastService,
        private templateService: TemplateService
        ) {
    }

    ngOnInit(): void {
        this.templateAreas = [];
        this.templateColors = [];
        if(this.edit){
            this.loadData();
        }
    }

    public submit(): void {
        // console.log(this.user);
        if (!this.dataIsValid()) {
            return;
        }
        this.dialogRef.close({
            save: true,
            template: this.template,
        });
    }

    private dataIsValid(): boolean {
        if (
            this.template.name == '' || this.template.name == undefined ||
            this.template.scalingFactor == 0 || this.template.scalingFactor == undefined
        ) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD, 4000);
            return false;
        }

        return true;
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

    private loadData(): void{
        this.templateService.getAllTemplateAreas(this.template.templateId).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
            },
            next: (value: GenericResponse<TemplateArea[]>) => {
                this.templateAreas = value.body;
            },
        });
        this.templateService.getAllTemplateColors(this.template.templateId).subscribe({
            error: err => {
                // tslint:disable-next-line:no-console
                console.log(err);
            },
            next: (value: GenericResponse<TemplateColor[]>) => {
                this.templateColors = value.body;
            },
        });
    }

    public openTemplateColors(color?: TemplateColor): void{

        const modal = this.modalController.open(TemplateColorComponentComponent, {
            closeOnBackdropClick  : false,
            context: {
                templateId: this.template.templateId,
                templateColor: color != undefined ? Object.assign({},color) : undefined,
                edit: color != undefined,
            },
        });
        modal.onClose.subscribe({
            next: res => {
                // console.log(res);
                if (res === undefined) {
                    return;
                }
                if (res.save && res.color !== undefined) {
                    this.templateService.createTemplateColor(res.color).subscribe({
                        error: err => {
                            // tslint:disable-next-line:no-console
                            console.log(err);
                            // TODO: Add correct error message
                            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.CREATE_TEMPLATE_FAILED, 10000, err);
                        },
                        next: value => {
                            this.loadData();
                            // TODO: Add success Message
                        },
                        complete: () => this.loadData(),
                    });
                }else if(!res.save && res.color !== undefined){
                    this.templateService.updateTemplateColor(res.color).subscribe({
                        error: err => {
                            // tslint:disable-next-line:no-console
                            console.log(err);
                            // TODO: Add correct error message
                            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.CREATE_TEMPLATE_FAILED, 10000, err);
                        },
                        next: value => {
                            this.loadData();
                            // TODO: Add success Message
                        },
                        complete: () => this.loadData(),
                    });
                }
            },
        });
    }

    public openTemplateArea(area?: TemplateArea): void{

        const modal = this.modalController.open(TemplateAreaComponentComponent, {
            closeOnBackdropClick  : false,
            context: {
                templateId: this.template.templateId,
                templateArea: area != undefined ? Object.assign({}, area) : undefined,
                edit: area != undefined,
            },
        });
        modal.onClose.subscribe({
            next: res => {
                // console.log(res);
                if (res === undefined) {
                    return;
                }
                if (res.save && res.area !== undefined) {
                    this.templateService.createTemplateArea(res.area).subscribe({
                        error: err => {
                            // tslint:disable-next-line:no-console
                            console.log(err);
                            // TODO: Add correct error message
                            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.CREATE_TEMPLATE_FAILED, 10000, err);
                        },
                        next: value => {
                            this.loadData();
                            // TODO: Add success Message
                            //
                        },
                    });
                }else if(!res.save && res.area !== undefined){
                    this.templateService.updateTemplateArea(res.area).subscribe({
                        error: err => {
                            // tslint:disable-next-line:no-console
                            console.log(err);
                            // TODO: Add correct error message
                            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.CREATE_TEMPLATE_FAILED, 10000, err);
                        },
                        next: value => {
                            this.loadData();
                            // TODO: Add success Message
                        },
                    });
                }
            },
        });
    }

    public deleteTemplateArea(area: TemplateArea): void{
        const modal = this.modalController.open(ConfirmComponent,{
            closeOnBackdropClick  : false,
        });
        modal.onClose.subscribe({
            next: res => {
                if(res != undefined && res.delete != undefined && res.delete){
                    this.templateService.deleteTemplateArea(area).subscribe({
                        error: err => {
                            // tslint:disable-next-line:no-console
                            console.log(err);
                            // TODO: Add error message
                        },
                        next: value => {
                            // TODO: Add success Message
                            this.loadData();
                        },
                    });
                }
            },
        });
    }

    public deleteTemplateColor(color: TemplateColor): void{
        const modal = this.modalController.open(ConfirmComponent,{
            closeOnBackdropClick  : false,
        });
        modal.onClose.subscribe({
            next: res => {
                if(res != undefined && res.delete != undefined && res.delete){
                    this.templateService.deleteTemplateColor(color).subscribe({
                        error: err => {
                            // tslint:disable-next-line:no-console
                            console.log(err);
                            // TODO: Add error message
                        },
                        next: value => {
                            // TODO: Add success Message
                            this.loadData();
                        },
                    });
                }
            },
        });
    }

}

<div class="">
    <div class="col-12 d-flex justify-content-center">
        <button nbButton (click)="closeDialogue()" class="close-btn"><i class="fas fa-times"></i></button>
    </div>
</div>
<nb-card>
    <nb-card-header>
        <div class="row">
            <div class="col-sm-12">
                <h5 *ngIf="!edit">Projekt hinzuf&uuml;gen</h5>
                <h5 *ngIf="edit">Projekt bearbeiten</h5>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <nb-spinner *ngIf="loading"></nb-spinner>
        <form *ngIf="!loading">
            <div class="row">
                <div class="col-12 mandatoryCol">
                    <span class="mandatory">*</span> Pflichtfeld
                </div>
            </div>
            <div class="form-group row">
                <label *ngIf="autocompleteUsers != undefined" class="col-sm-2 label" for="selectuser">Nutzer <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <ngx-autocomplete
                        *ngIf="autocompleteUsers != undefined"
                        [(value)]="selectedUserAutocomplete"
                        [items]="autocompleteUsers"
                        (onChange)="onAutocompleteChange($event, 'user')"
                        (onSelect)="onAutocompleteSelect($event, 'user')"
                    ></ngx-autocomplete>
                </div>
                <label *ngIf="autocompleteChildren != undefined" class="col-sm-2 label" for="selectchild">Kind <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <ngx-autocomplete
                        *ngIf="autocompleteChildren != undefined"
                        [(value)]="selectedChildAutocomplete"
                        [items]="autocompleteChildren"
                        (onChange)="onAutocompleteChange($event, 'child')"
                        (onSelect)="onAutocompleteSelect($event, 'child')"
                    ></ngx-autocomplete>
                </div>
            </div>
            <div class="form-group row">
                <label *ngIf="autocompleteTemplates != undefined" class="col-sm-2 label" for="template">Template <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <ngx-autocomplete
                        *ngIf="autocompleteTemplates != undefined"
                        [(value)]="selectedTemplateAutocomplete"
                        [items]="autocompleteTemplates"
                        (onChange)="onAutocompleteChange($event, 'template')"
                        (onSelect)="onAutocompleteSelect($event, 'template')"
                    ></ngx-autocomplete>
                </div>
                <label *ngIf="selectedTemplateAutocomplete != undefined && autocompleteTemplateColors != undefined" class="col-sm-2 label" for="templateColor">Template Farbe <span class="mandatory">*</span></label>
                <div class="col-sm-4">
                    <ngx-autocomplete
                        *ngIf="selectedTemplateAutocomplete != undefined && autocompleteTemplateColors != undefined"
                        [(value)]="selectedTemplateColorAutocomplete"
                        [items]="autocompleteTemplateColors"
                        (onChange)="onAutocompleteChange($event, 'templatecolor')"
                        (onSelect)="onAutocompleteSelect($event, 'templatecolor')"
                    ></ngx-autocomplete>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <div class="form-group row btnRow">
            <div class="col-12 col-sm-4">
                <button nbButton fullWidth
                        (click)="startPrint()"
                        *ngIf="project.printableFileId == undefined || project.printableFileId == ''">Druckdatei generieren</button>
                <button nbButton fullWidth
                        (click)="download()"
                        *ngIf="project.printableFileId != undefined && project.printableFileId != ''">Download</button>
            </div>
            <div class="col-12 col-sm-4">
                <button nbButton fullWidth (click)="prePrint()">
                    <span class="spinner" *ngIf="prePrintLoading != undefined && prePrintLoading" [nbSpinner]="true" style="min-height: 16px; background-color: transparent; overflow: visible"></span>
                    <span *ngIf="prePrintLoading == undefined || !prePrintLoading">Vorschau</span>
                </button>
            </div>
            <div class="col-12 col-sm-4">
                <button nbButton fullWidth (click)="submit()">Speichern</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>

import { Component, Input, OnInit } from '@angular/core';
import { AutocompleteItem } from 'app/components/autocomplete/autocomplete.component';
import { Child } from 'app/models/Child.model';
import { GetAllUserResponse } from 'app/models/response/user/GetAllUserResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NbDialogRef } from '@nebular/theme';
import { ToastService } from 'app/services/toast.service';
import { UserService } from 'app/services/user.service';

@Component({
    selector: 'ngx-child-dialog',
    templateUrl: './child-dialog.component.html',
    styleUrls: ['./child-dialog.component.scss'],
})
export class ChildDialogComponent implements OnInit {

    @Input()
    public child: Child;
    @Input()
    public update: boolean;
    @Input()
    public edit: boolean;
    public autocompleteItems: AutocompleteItem[];
    public selectedAutocompleteItem: AutocompleteItem;

    constructor(
        protected dialogRef: NbDialogRef<any>,
        private toastService: ToastService,
        private userService: UserService
    ) { }

    public async ngOnInit(): Promise<void> {
        await this.setAutocompleteItems();
    }

    private async setAutocompleteItems(): Promise<void> {
        await this.userService.getAllUsers().toPromise()
        .catch((err: HttpErrorResponse) => {
            // tslint:disable-next-line:no-console
            console.log(err);
        })
        .then((res: GetAllUserResponse) => {
            if (res === undefined) return;
            const items: AutocompleteItem[] = [];
            res.body.forEach(x => {
                const item = new AutocompleteItem();
                item.display = `${x.firstName} ${x.lastName}`;
                item.filter = item.display;
                item.value = x;
                items.push(item);
                if (x.userId === this.child.userId) {
                    this.selectedAutocompleteItem = item;
                }
            });
            this.autocompleteItems = items;
        });
    }

    public onUserSelect(event: AutocompleteItem): void {
        this.selectedAutocompleteItem = event;
    }

    public onAutocompleteChange(event: string): void {
        this.autocompleteItems.forEach(item => {
            if (item.display.toLocaleLowerCase() === event.toLocaleLowerCase()) {
                this.selectedAutocompleteItem = item;
                return;
            } else {
                this.selectedAutocompleteItem = undefined;
            }
        });
    }

    public submit(): void {
        // console.log(this.user);
        this.child.userId = this.selectedAutocompleteItem.value.userId;
        if (!this.dataIsValid()) {
            return;
        }
        this.dialogRef.close({
            save: this.update === undefined || this.update === false ? true : false,
            update: this.update,
            child: this.child,
        });
    }

    private dataIsValid(): boolean {
        if (
            this.child.name == '' || this.child.name == undefined ||
            this.child.groupName == '' || this.child.groupName == undefined ||
            this.child.startYear == '' || this.child.startYear == undefined ||
            this.child.endYear == '' || this.child.endYear == undefined ||
            this.child.userId == '' || this.child.userId == undefined
        ) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.MISSING_ANY_MANDATORY_FIELD, 4000);
            return false;
        }

        return true;
    }

    public closeDialogue(): void{
        this.dialogRef.close({reload: false});
    }

}

export class TemplateColor{

    public templateColorId: string;
    public templateId: string;
    public name: string;
    public showingFileId: string;
    public mobileShowingFileId: string;
    public printingFileId: string;
    public thumbnailFileId: string;
}

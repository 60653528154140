import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class ToastService {

    private MESSAGE_PLACEHOLDER = '%str_replace%';
    public LOGIN_FAILED = {
        title: 'Login fehlgeschlagen!',
        body: `Es ist ein Fehler aufgetreten, überprüfen Sie Ihre Eingabe und versuchen Sie es erneut!\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public LOGIN_FAILED_NO_ADMIN = {
        title: 'Login fehlgeschlagen!',
        body: `In diesem Bereich können sich nur Administratoren einloggen!`,
    };
    public LOGIN_MISSING = {
        title: 'Fehlerhafte Eingabe!',
        body: 'Bitte füllen Sie alle Eingabefelder aus und versuchen Sie es erneut!',
    };
    public TOKEN_EXPIRED = {
        title: 'Aus Sicherheitsgründen wurden Sie abgemeldet!',
        body: 'Bitte melden Sie sich erneut an!',
    };
    public SAVE_SUCCESS = {
        title: 'Speichern erfolgreich!',
        body: 'Ihre Daten wurden erfolgreich gespeichert.',
    };
    public LOADING_DATA_FAILED = {
        title: 'Fehler beim Laden der Daten!',
        body: `Beim Laden der Daten ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public PRINT_FILE_FAILED = {
        title: 'Fehler beim Erstellen der Druckdatei!',
        body: `Beim Erstellen der Druckdatei ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public CREATE_SETTING_FAILED = {
        title: 'Fehler beim Erstellen der Einstellung!',
        body: `Beim Erstellen der Einstellung ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SAVE_SETTING_FAILED = {
        title: 'Fehler beim Speichern der Einstellung!',
        body: `Beim Speichern der Einstellung ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SETTINGS_UPDATE_FAILED = {
        title: 'Fehler beim ändern der Einstellung',
        body: `Beim ändern der Einstellungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SETTINGS_UPDATE_SUCCESS = {
        title: 'Einstellung erfolgreich geändert',
        body: `Die Einstellung wurde erfolgreich geändert.`,
    };
    public SETTINGS_LOAD_FAILED = {
        title: 'Fehler beim laden der Einstellungen',
        body: `Beim laden der Einstellungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SETTINGS_DUPLICATED_KEY = {
        title: 'Fehler beim erstellen der Einstellung',
        body: 'Der eingetragene Schlüssel ist bereits vorhanden!',
    };
    public SETTINGS_CREATE_FAILED = {
        title: 'Fehler beim erstellen der Einstellung',
        body: `Beim erstellen der Einstellungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SETTINGS_DELETE_FAILED = {
        title: 'Fehler beim löschen der Einstellung',
        body: `Beim löschen der Einstellungen ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public SETTINGS_DELETE_SUCCESS = {
        title: 'Einstellung erfolgreich gelöscht',
        body: `Die Einstellung wurde erfolgreich gelöscht.`,
    };
    public SETTINGS_CREATE_SUCCESS = {
        title: 'Einstellung erfolgreich erstellt',
        body: `Die Einstellung wurde erfolgreich erstellt.`,
    };
    public MISSING_MANDATORY_FIELD = {
        title: 'Fehlerhafte Eingabe!',
        body: `Bitte füllen Sie alle Pflichtfelder aus und versuchen Sie es erneut! (Fehlendes Feld: ${this.MESSAGE_PLACEHOLDER})`,
    };
    public PASSWORDS_DONT_MATCH = {
        title: 'Die eingegebenen Passwörter stimmen nicht überein!',
        body: 'Bitte überprüfen Sie Ihre Eingabe und versuchen es erneut!',
    };
    public MISSING_ANY_MANDATORY_FIELD = {
        title: 'Fehlerhafte Eingabe!',
        body: `Bitte füllen Sie alle Pflichtfelder aus und versuchen Sie es erneut!`,
    };
    public DELETE_USER_SUCCESS = {
        title: 'Nutzer erfolgreich gelöscht!',
        body: `Der Nutzer wurde erfolgreich gelöscht.`,
    };
    public DELETE_USER_FAILED = {
        title: 'Fehler beim Löschen des Nutzers!',
        body: `Beim Löschen des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public CREATE_USER_SUCCESS = {
        title: 'Nutzer erfolgreich angelegt!',
        body: `Der Nutzer wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
    };
    public CREATE_USER_FAILED = {
        title: 'Fehler beim Anlegen des Nutzers!',
        body: `Beim Anlegen des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public CREATE_TEMPLATE_SUCCESS = {
        title: 'Template erfolgreich angelegt!',
        body: `Das Template wurde erfolgreich angelegt.`,
    };
    public CREATE_TEMPLATE_FAILED = {
        title: 'Fehler beim Anlegen des Templates!',
        body: `Beim Anlegen des Templates ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public UPDATE_TEMPLATE_SUCCESS = {
        title: 'Template erfolgreich geändert!',
        body: `Das Template wurde erfolgreich geändert.`,
    };
    public UPDATE_TEMPLATE_FAILED = {
        title: 'Fehler beim Ändern des Templates!',
        body: `Beim Ändern des Templates ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public DELETE_TEMPLATE_SUCCESS = {
        title: 'Template erfolgreich gelöscht!',
        body: `Das Template wurde erfolgreich gelöscht.`,
    };
    public DELETE_TEMPLATE_FAILED = {
        title: 'Fehler beim Löschen des Templates!',
        body: `Beim Löschen des Templates ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public UPDATE_USER_SUCCESS = {
        title: 'Nutzer erfolgreich geändert!',
        body: `Der Nutzer wurde erfolgreich geändert.`,
    };
    public UPDATE_USER_FAILED = {
        title: 'Fehler beim Ändern des Nutzers!',
        body: `Beim Ändern des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public DELETE_CHILD_SUCCESS = {
        title: 'Kind erfolgreich gelöscht!',
        body: `Das Kind wurde erfolgreich gelöscht.`,
    };
    public DELETE_CHILD_FAILED = {
        title: 'Fehler beim Löschen des Kindes!',
        body: `Beim Löschen des Kindes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public CREATE_CHILD_SUCCESS = {
        title: 'Kind erfolgreich angelegt!',
        body: `Das Kind wurde erfolgreich angelegt.`,
    };
    public CREATE_CHILD_FAILED = {
        title: 'Fehler beim Anlegen des Kindes!',
        body: `Beim Anlegen des Kindes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public UPDATE_CHILD_SUCCESS = {
        title: 'Kind erfolgreich geändert!',
        body: `Das Kind wurde erfolgreich geändert.`,
    };
    public UPDATE_CHILD_FAILED = {
        title: 'Fehler beim Ändern des Kindes!',
        body: `Beim Ändern des Kindes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public CANNOT_DELETE_SELF = {
        title: 'Fehler beim Löschen des Nutzers!',
        body: `Sie dürfen den Nutzer löschen mit dem Sie angemeldet sind.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public DELETE_PROJECT_SUCCESS = {
        title: 'Projekt erfolgreich gelöscht!',
        body: `Das Projekt wurde erfolgreich gelöscht.`,
    };
    public DELETE_PROJECT_FAILED = {
        title: 'Fehler beim Löschen des Projektes!',
        body: `Beim Löschen des Projektes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public CREATE_PROJECT_SUCCESS = {
        title: 'Projekt erfolgreich angelegt!',
        body: `Das Projekt wurde erfolgreich angelegt.`,
    };
    public CREATE_PROJECT_FAILED = {
        title: 'Fehler beim Anlegen des Projektes!',
        body: `Beim Anlegen des Projektes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public UPDATE_PROJECT_SUCCESS = {
        title: 'Projekt erfolgreich geändert!',
        body: `Das Projekt wurde erfolgreich geändert.`,
    };
    public UPDATE_PROJECT_FAILED = {
        title: 'Fehler beim Ändern des Projektes!',
        body: `Beim Ändern des Projektes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public REQUEST_RESET_PASSWORD_FAILED = {
        title: 'Fehler beim anfragen des Zurücksetzen!',
        body: `Beim anfragen zum zurücksetzen des Passwortes ist ein Fehler aufgetreten. Überprüfen Sie die eingegebene
        E-Mail Adresse und versuchen Sie es erneut. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public REQUEST_RESET_PASSWORD_SUCCESS = {
        title: 'Anfrage erfolgreich versendet!',
        body: `Die Zurücksetzung des Passwortes wurde erfolgreich beantragt.`,
    };
    public RESET_PASSWORD_FAILED = {
        title: 'Fehler beim ändern des Passwortes!',
        body: `Beim ändern des Passwortes ist ein Fehler aufgetreten. \n ${this.MESSAGE_PLACEHOLDER}`,
    };
    public RESET_PASSWORD_SUCCESS = {
        title: 'Passwort erfolgreich geändert!',
        body: `Das Passwort wurde erfolgreich geändert.`,
    };
    public LOAD_ITEMS_FAILED = {
        title: 'Laden der Projekt-Elemente fehlgeschlagen.',
        body: `Die Elemente des Projektes '${this.MESSAGE_PLACEHOLDER}' konnten nicht geladen werden.`,
    };
    public TOAST_STATUS = {
        primary: 0,
        success: 1,
        info: 2,
        warning: 3,
        danger: 4,
    };
    private types: NbComponentStatus[] = [
        'primary',
        'success',
        'info',
        'warning',
        'danger',
    ];
    private defaultSuccessDuration = 5000;
    private hasIcon = true;
    private position = NbGlobalPhysicalPosition.TOP_RIGHT;
    private preventDuplicates = false;

    constructor(
        private toastrService: NbToastrService
    ) { }

    public showToast(type: number, message: any, durationInMs: number = -1, additionalInfo: any = '') {
        if(durationInMs === -1){
            if(type === 1){
                durationInMs = this.defaultSuccessDuration;
            }else{
                durationInMs = 0;
            }
        }
        const config = {
            status: this.types[type],
            destroyByClick: true,
            duration: durationInMs,
            hasIcon: this.hasIcon,
            position: this.position,
            preventDuplicates: this.preventDuplicates,
        };

        if(additionalInfo instanceof HttpErrorResponse){
            if(additionalInfo.error != undefined && additionalInfo.error.errors != undefined && additionalInfo.error.errors[0]){
                additionalInfo = additionalInfo.error.errors[0];
            }else{
                additionalInfo = '';
            }
        }

        const titleContent = message.title ? `${message.title}` : '';
        const bodyContent = message.body ? `${message.body.replace(this.MESSAGE_PLACEHOLDER,additionalInfo)}` : '';

        this.toastrService.show(
            bodyContent,
            titleContent,
            config);
    }
    public downloadBase64File(data: string, name: string): void{
        let link = document.createElement('a');
        link.href = data;
        link.download = name;
        link.click();
        link.remove();
    }

}

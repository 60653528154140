import * as ROUTES from "./ROUTES";
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginRequest } from 'app/models/requests/LoginRequest.model';
import { LoginResponse } from 'app/models/response/LoginResponse.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor(
        private router: Router,
        private http: HttpClient,
        private dataService: DataService
    ) {}

    public login(email: string, password: string): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${ROUTES.USER_ROUTE}/login`, new LoginRequest(email, password), this.dataService.getHttpOptionsNoToken());
    }

    public logout(): void {
        this.dataService.clearData();
        this.dataService.setToken(undefined);
        this.router.navigate(['/login']);
    }

    public validateToken(token: string): Observable<any> {
        return this.http.post(`${ROUTES.USER_ROUTE}`, {}, this.dataService.getHttpOptionsWithToken(token));
    }

}
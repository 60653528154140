import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    NbActionsModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbSelectModule,
    NbSpinnerModule,
    NbStepperModule,
    NbTooltipModule,
} from '@nebular/theme';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ChildDialogComponent } from './dialogs/childDialogComponent/child-dialog.component';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './smart-table/confirm/confirm.component';
import { ConfirmMultipleComponent } from './confirm-multiple/confirm-multiple.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { Ng2CompleterModule } from '@akveo/ng2-completer';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgModule } from '@angular/core';
import {NgxFileDropModule} from "ngx-file-drop";
import { ProjectDialogComponent } from './dialogs/projectDialogComponent copy/project-dialog.component';
import { ProjectStatusColComponent } from './smart-table/customComponents/project-status-col.component';
import { SmartTableComponent } from './smart-table/smart-table.component';
import { TemplateAreaComponentComponent } from './dialogs/template-area-component/template-area-component.component';
import { TemplateColorComponentComponent } from './dialogs/template-color-component/template-color-component.component';
import {TemplateDialogComponent} from "./dialogs/templateDialogComponent/template-dialog.component";
import { UserDialogComponent } from './dialogs/userDialogComponent/user-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        Ng2CompleterModule,
        Ng2SmartTableModule,
        NbCardModule,
        NbDatepickerModule,
        NbStepperModule,
        NbInputModule,
        NbButtonModule,
        NbAutocompleteModule,
        NbSelectModule,
        ReactiveFormsModule,
        NbCheckboxModule,
        NbTooltipModule,
        NbSpinnerModule,
        DragDropModule,
        NbActionsModule,
        NbIconModule,
        NbLayoutModule,
        NgxFileDropModule,
    ],
    declarations: [
        UserDialogComponent,
        ConfirmComponent,
        SmartTableComponent,
        ChildDialogComponent,
        AutocompleteComponent,
        TemplateDialogComponent,
        ProjectDialogComponent,
        TemplateAreaComponentComponent,
        TemplateColorComponentComponent,
        ProjectStatusColComponent,
        ConfirmMultipleComponent,
    ],
    exports: [
        UserDialogComponent,
        ConfirmComponent,
        SmartTableComponent,
        ChildDialogComponent,
        AutocompleteComponent,
        TemplateDialogComponent,
        ProjectDialogComponent,
    ],
})
export class SharedModule { }

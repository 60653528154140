
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
} from './utils';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { NbAuthModule, NbDummyAuthStrategy } from '@nebular/auth';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';

import { CommonModule } from '@angular/common';
import { CountryOrderData } from './data/country-order';
import { CountryOrderService } from './mock/country-order.service';
import { EarningData } from './data/earning';
import { EarningService } from './mock/earning.service';
import { ElectricityData } from './data/electricity';
import { ElectricityService } from './mock/electricity.service';
import { MockDataModule } from './mock/mock-data.module';
import { OrdersChartData } from './data/orders-chart';
import { OrdersChartService } from './mock/orders-chart.service';
import { OrdersProfitChartData } from './data/orders-profit-chart';
import { OrdersProfitChartService } from './mock/orders-profit-chart.service';
import { ProfitBarAnimationChartData } from './data/profit-bar-animation-chart';
import { ProfitBarAnimationChartService } from './mock/profit-bar-animation-chart.service';
import { ProfitChartData } from './data/profit-chart';
import { ProfitChartService } from './mock/profit-chart.service';
import { SecurityCamerasData } from './data/security-cameras';
import { SecurityCamerasService } from './mock/security-cameras.service';
import { SmartTableData } from './data/smart-table';
import { SmartTableService } from './mock/smart-table.service';
import { SolarData } from './data/solar';
import { SolarService } from './mock/solar.service';
import { StatsBarData } from './data/stats-bar';
import { StatsBarService } from './mock/stats-bar.service';
import { StatsProgressBarData } from './data/stats-progress-bar';
import { StatsProgressBarService } from './mock/stats-progress-bar.service';
import { TemperatureHumidityData } from './data/temperature-humidity';
import { TemperatureHumidityService } from './mock/temperature-humidity.service';
import { TrafficBarData } from './data/traffic-bar';
import { TrafficBarService } from './mock/traffic-bar.service';
import { TrafficChartData } from './data/traffic-chart';
import { TrafficChartService } from './mock/traffic-chart.service';
import { TrafficListData } from './data/traffic-list';
import { TrafficListService } from './mock/traffic-list.service';
import { UserActivityData } from './data/user-activity';
import { UserActivityService } from './mock/user-activity.service';
import { UserData } from './data/users';
import { UserService } from './mock/users.service';
import { VisitorsAnalyticsData } from './data/visitors-analytics';
import { VisitorsAnalyticsService } from './mock/visitors-analytics.service';
import { of as observableOf } from 'rxjs';
import { throwIfAlreadyLoaded } from './module-import-guard';

const socialLinks = [
    {
        url: 'https://github.com/akveo/nebular',
        target: '_blank',
        icon: 'github',
    },
    {
        url: 'https://www.facebook.com/akveo/',
        target: '_blank',
        icon: 'facebook',
    },
    {
        url: 'https://twitter.com/akveo_inc',
        target: '_blank',
        icon: 'twitter',
    },
];

const DATA_SERVICES = [
    { provide: UserData, useClass: UserService },
    { provide: ElectricityData, useClass: ElectricityService },
    { provide: SmartTableData, useClass: SmartTableService },
    { provide: UserActivityData, useClass: UserActivityService },
    { provide: OrdersChartData, useClass: OrdersChartService },
    { provide: ProfitChartData, useClass: ProfitChartService },
    { provide: TrafficListData, useClass: TrafficListService },
    { provide: EarningData, useClass: EarningService },
    { provide: OrdersProfitChartData, useClass: OrdersProfitChartService },
    { provide: TrafficBarData, useClass: TrafficBarService },
    { provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService },
    { provide: TemperatureHumidityData, useClass: TemperatureHumidityService },
    { provide: SolarData, useClass: SolarService },
    { provide: TrafficChartData, useClass: TrafficChartService },
    { provide: StatsBarData, useClass: StatsBarService },
    { provide: CountryOrderData, useClass: CountryOrderService },
    { provide: StatsProgressBarData, useClass: StatsProgressBarService },
    { provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService },
    { provide: SecurityCamerasData, useClass: SecurityCamerasService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
    getRole() {
        // here you could provide any role based on any auth flow
        return observableOf('guest');
    }
}

export const NB_CORE_PROVIDERS = [
    ...MockDataModule.forRoot().providers,
    ...DATA_SERVICES,
    ...NbAuthModule.forRoot({

      strategies: [
          NbDummyAuthStrategy.setup({
              name: 'email',
              delay: 3000,
          }),
      ],
      forms: {
          login: {
              socialLinks: socialLinks,
          },
          register: {
              socialLinks: socialLinks,
          },
        },
    }).providers,

    NbSecurityModule.forRoot({
        accessControl: {
            guest: {
                view: '*',
            },
            user: {
                parent: 'guest',
                create: '*',
                edit: '*',
                remove: '*',
            },
        },
    }).providers,

    {
        provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
    },
    AnalyticsService,
    LayoutService,
    PlayerService,
    SeoService,
    StateService,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        NbAuthModule,
    ],
    declarations: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }
}

import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { DataService } from 'app/services/data.service';
import {GetUserResponse} from "../../models/response/user/GetUserResponse.model";
import { HttpErrorResponse } from '@angular/common/http';
import { LoginResponse } from 'app/models/response/LoginResponse.model';
import { Router } from '@angular/router';
import { ToastService } from 'app/services/toast.service';
import {UserService} from "../../services/user.service";

@Component({
    selector: 'ngx-login',
    styleUrls: ['./login.component.scss'],
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, AfterViewInit {

    public loginName: string;
    public password: string;

    constructor(
      private router: Router,
      private authService: AuthService,
      private dataService: DataService,
      private toastService: ToastService,
      private userService: UserService
    ) { }

    public ngOnInit(): void {
        this.disableLoadingSpinner();
    }

    public ngAfterViewInit() {
        if (history.state.authRedirect) {
            this.showErrToast();
            history.state.authRedirect = false;
        }

        if (history.state.resetPWSuccess) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.REQUEST_RESET_PASSWORD_SUCCESS, 3000);
            history.state.resetPWSuccess = false;
        }

        if (history.state.resetPWConfirmSuccess) {
            this.toastService.showToast(this.toastService.TOAST_STATUS.success, this.toastService.RESET_PASSWORD_SUCCESS, 3000);
            history.state.resetPWConfirmSuccess = false;
        }
    }

    public async login(): Promise<void> {
        if (this.loginName == undefined || this.loginName == '' || this.password == undefined || this.password == '') {
            this.toastService.showToast(this.toastService.TOAST_STATUS.warning, this.toastService.LOGIN_MISSING, 10000);
            this.password = '';
            return;
        }
        await this.authService.login(this.loginName, this.password).toPromise()
        .catch((err: HttpErrorResponse) => {
            // tslint:disable-next-line:no-console
            console.log(err);
            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOGIN_FAILED, 0, err);
        })
        .then((res: LoginResponse) => {
            if (res === undefined) return;
            if (res.status == 200) {
                this.dataService.setToken(res.token);
                this.userService.getUserSelf().subscribe({
                    error: err => {
                        // tslint:disable-next-line:no-console
                        console.log(err);
                        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOGIN_FAILED, 0, err);
                    },
                    next: (value: GetUserResponse) => {
                        if(value.body.admin){
                            this.router.navigate(['/pages/dashboard']);
                        }else{
                            this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.LOGIN_FAILED_NO_ADMIN, 0);
                        }
                    },
                });
            }
        });
    }

    private disableLoadingSpinner(): void {
        const el = document.getElementById('nb-global-spinner');
        if (el) {
            el.style['display'] = 'none';
        }
    }

    private showErrToast(): void {
        this.toastService.showToast(this.toastService.TOAST_STATUS.danger, this.toastService.TOKEN_EXPIRED, 0);
    }

    public onKeypress(event: any): void {
        if (event.code === 'Enter') {
            this.login();
        }
    }

    public navigateToResetPassword(): void {
        this.router.navigate(['reset-password']);
    }

}

import * as ROUTES from "./ROUTES";
import { CreateUserRequest } from 'app/models/requests/user/CreateUserRequest.model';
import { DataService } from './data.service';
import { GetAllUserResponse } from 'app/models/response/user/GetAllUserResponse.model';
import { GetUserResponse } from "app/models/response/user/GetUserResponse.model";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdateUserRequest } from 'app/models/requests/user/UpdateUserRequest.model';
import { User } from 'app/models/User.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) {}

    public getUserSelf(): Observable<GetUserResponse> {
        return this.http.get<GetUserResponse>(`${ROUTES.USER_ROUTE}/`, this.dataService.getHttpOptionsWithToken());
    }

    // TODO: change return any to corresponding response
    public updateUser(user: User): Observable<any> {
        return this.http.put(`${ROUTES.USER_ROUTE}/${user.userId}/`, new UpdateUserRequest(user),this.dataService.getHttpOptionsWithToken());
    }

    // TODO: change return any to corresponding response
    public deleteUser(userId: string): Observable<any> {
        return this.http.delete(`${ROUTES.USER_ROUTE}/${userId}/`, this.dataService.getHttpOptionsWithToken());
    }

    // TODO: change return any to corresponding response
    public createUser(user: User): Observable<any> {
        return this.http.post(`${ROUTES.USER_ROUTE}/`, new CreateUserRequest(user), this.dataService.getHttpOptionsWithToken());
    }

    public getUserById(userId: string): Observable<GetUserResponse> {
        return this.http.get<GetUserResponse>(`${ROUTES.USER_ROUTE}/${userId}/`, this.dataService.getHttpOptionsWithToken());
    }

    public getAllUsers(onlyActiveUsers: boolean = true): Observable<GetAllUserResponse> {
        return this.http.get<GetAllUserResponse>(`${ROUTES.USER_ROUTE}/all/${onlyActiveUsers}`, this.dataService.getHttpOptionsWithToken());
    }

    // TODO: change return any to corresponding response
    public resetPassword(email: string): Observable<any> {
        return this.http.post(`${ROUTES.USER_ROUTE}/resetPassword/`, {email}, this.dataService.getHttpOptionsNoToken());
    }

    // TODO: change return any to corresponding response
    public resetPasswordConfirm(newPassword, passwordResetToken): Observable<any> {
        return this.http.post(`${ROUTES.USER_ROUTE}/resetPassword/confirm/`,
        {passwordResetToken, newPassword}, this.dataService.getHttpOptionsWithToken(passwordResetToken));
    }

    public generatePassword(): string {
        let passwordLength = 12;

        let lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        let upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let symbols = ['!', '?', '@'];

        let getRandom = function (array) {
            return array[Math.floor(Math.random() * array.length)];
        };
        let finalCharacters = "";

        finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
        finalCharacters = finalCharacters.concat(getRandom(numbers));
        finalCharacters = finalCharacters.concat(getRandom(symbols));

        for (let i = 1; i < passwordLength - 3; i++) {
            finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
        }

        // shuffle!
        return finalCharacters.split('').sort(function () {
            return 0.5 - Math.random();
        }).join('');
    }
}
import { Child } from "app/models/Child.model";
import { CreateChildRequest } from "./CreateChildRequest.model";

export class UpdateChildRequest extends CreateChildRequest {

    public childId: string;

    constructor(child: Child) {
        super(child);
        this.childId = child.childId;
    }

    public toChild(child: Child): Child {
        const updateChild = super.toChild(child);
        updateChild.childId = this.childId;
        return updateChild;
    }

}
